import React, { useMemo } from 'react'
import { Box, Button } from '@mui/material'
import downloadFill from "@iconify/icons-eva/download-fill";
import { getIcon } from 'src/utils/utility';
import { changeToCSV, downloadCSV, saveToExcel } from 'src/utils/convertAnddownloadCsv';
import { yearFirstDateTimeFormat } from 'src/utils/formatTime';


export function isDateTimeField(fieldName) {
    return (/(date|time|createdAt|createAt|createdOn|createOn|updateAt|updatedAt|updateOn|updatedOn)/gmi).test(fieldName)
}

const ExportCSV = ({ rows, columns, csvMorphemes }) => {

    const csvObject = useMemo(() => {
        let csvObject = {};
        csvObject["headers"] = columns.map((column) => column.headerName);
        csvObject["properties"] = columns.map((column) => column.field)
        csvObject["valuesObjArr"] = rows.map((row) => {
            let rowValue = { ...row }
            columns.forEach((column) => {
                //DONE AS TO FORMAT FROM UTC TO LOCAL TIME FORMAT
                if (isDateTimeField(column.field)) {
                    rowValue = { ...rowValue, [column.field]: yearFirstDateTimeFormat(row[column.field]) }
                }
                //DONE AS THE VALUES COME IN BINARY FORM
                if (csvMorphemes === "auth_code" && (column.field === "blocked" || column.field === "in_transaction")) {
                    rowValue = { ...rowValue, [column.field]: !!(row[column.field]) }
                }
                //DONE AS FULL NAME COMES IN 2 PARTS FROM BACKEND
                if (csvMorphemes === "ev_drivers" && column.field === "fullName") {
                    rowValue = { ...rowValue, [column.field]: `${row.firstName} ${row.lastName}` }
                }
            });
            return rowValue;
        });
        return csvObject;
    }, [rows, columns, csvMorphemes]);

    const handleExport = () => {
        // const csvData = changeToCSV(csvObject);
        saveToExcel(csvObject, csvMorphemes)
        // downloadCSV(csvData, csvMorphemes);
    }

    return (
        <Box sx={{display: "inline-block", position: "absolute", right:"40px", top:"32px"}}>
            <Button variant="contained" startIcon={getIcon(downloadFill)} onClick={handleExport}>
                Export To Excel
            </Button>
        </Box>
    )
}

export default ExportCSV