import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

export const roleColumns = [
    {
      field: "teamId",
      headerName: "ID",
      width: 100,
      renderCell: ({ value, id }) => (
        <Link underline="none" component={RouterLink} to={`/team/${id}`}>
          {value}
        </Link>
      ),
    },
    {
      field: "teamName",
      headerName: "Team Name",
      flex: 1,
    },
    {
      field: "accesstype",
      headerName: "Access Type",
      flex: 1,
    },
];

export const getUserProfileDefaultValues = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    street: "",
    houseNumber: "",
    zipCode: "",
    city: "",
    country: ""
}