import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { CompanyActionMenu } from "./CompanyActionMenu";

export const getCompanyDefaultValues = () => ({
  subscriptionId: "",
  emailId: "",
  name: "",
  address: {
    street: "",
    houseNumber: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
  },
  active: true,
  paymentConfirmationNo: "",
  rootUser: "",
  comments: "",
  validUntil: "",
  locationLongitude: "",
  locationLatitude: "",
  paymentCurrency: "",
  paymentGatewayId: "",
  transactionPercent: "",
  taxPercent: "",
  provider: {
    redIcon: "",
    greenIcon: "",
    icon: "",
    greyIcon: "",
  },
});

export const acceptableCurrencyList = [
  { value: "INR", title: "Indian Rupees" },
  { value: "CAD", title: "Canadian Dollars" },
];

export const companyListColumns = (isReadOnly) => [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: ({ value, id }) => (
      <>
        {isReadOnly ? (
          value
        ) : (
          <Link underline="none" component={RouterLink} to={`${id}`}>
            {value}
          </Link>
        )}
      </>
    ),
  },
  {
    field: "creationTime",
    headerName: "Creation Time",
    flex: 1,
    type: "date",
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: CompanyActionMenu,
  },
];
