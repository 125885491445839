import PropTypes from "prop-types";
import { useMemo } from "react";
// material
import { CssBaseline, alpha } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import ComponentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import { useDomainConfig } from "src/hooks/useDomainConfig";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

const addCssVariables = (primary,filterColor) => {
  const dynamicVariables = `
  :root 
  {
    --app-color: ${primary.main};
    --app-filter-color: ${filterColor}
  }
  `;
  const styleElement = document.createElement("style");
  styleElement.appendChild(document.createTextNode(dynamicVariables));
  document.head.appendChild(styleElement);
};

const addFaviconIcon=(domainConfig)=>{
  document.getElementById("favicon").href = domainConfig.faviconUrl
  document.title = domainConfig.title;
}

export default function ThemeConfig({ children }) 
{
  const domainConfig = useDomainConfig();
  addFaviconIcon(domainConfig)
  addCssVariables(domainConfig.palette.primary,domainConfig.filterColor);

  palette.primary = domainConfig.palette.primary;

  customShadows.primary = `0 8px 16px 0 ${alpha(palette.primary.main, 0.24)}`;

  const themeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows,
      customShadows,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
