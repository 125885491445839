import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import { alertSlice } from "src/store/alert/alert.slice";
import { FEATURE } from "src/util/enums";
import Page from "../Page";
import { TeamForm } from "./TeamForm";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";

export const Team = () => {
  const navigate = useNavigate();
  const isCreateTeam = useMatch("/team/new");
  const isReadOnly = useAccess(FEATURE.TEAM, "R");
  const notAuthorized = useAccess(FEATURE.TEAM, "N");
  const createAccess = useAccess(FEATURE.TEAM, "C");
  const dispatch = useDispatch();
  const { detailsId } = useSelector(
    ({ tabsCurrentlyOpenedFor }) => tabsCurrentlyOpenedFor
  );

  useEffect(() => {
    if ((isCreateTeam && !createAccess) || (!isCreateTeam && notAuthorized)) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate(sidebarConfig[FEATURE.TEAM].path);
    }
  }, [dispatch, isCreateTeam, createAccess, navigate, notAuthorized]);

  const onBackButtonClick = ()=>{
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId:"",
      tabOpenedFor:"",
      tabOpened:""
    }))
  }

  return (
    <Page >
      <BackButton
        text={
          isCreateTeam
            ? "Create Team"
            : isReadOnly
            ? "Team Details"
            : `Edit Team Details (${detailsId})`
        }
        path="/team"
        action={onBackButtonClick}
      />
      <TeamForm />
    </Page>
  );
};
