import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import { useCustomer, useGetHistory } from "src/hooks/useFetch.hook";
import { FEATURE } from "src/util/enums";
import { BaseTable } from "../BaseTable/BaseTable";
import Page from "../Page";
import { WalletHistoryListColumns } from "./walletHistory.constant";
import { GridLabel } from "../Transactions/TransactionView";
import { fDateTime } from "src/utils/formatTime";

export default function WalletHistoryList() {
  const { id: customerId } = useParams();
  const { data: customerDetail, isFetching: isCustomerDetailFetching } =
    useCustomer(customerId);
  const { data: history, isFetching } = useGetHistory(
    "walletHistoryList",
    customerId
  );
  const isReadOnly = useAccess(FEATURE.CUSTOMERS, "R");
  const [tableData, setTableData] = useState();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    let data = [];
    if (!isFetching) {
      if (history.data && history.data.length > 0) {
        data = history.data;
      }
      setTableData(data);
    }
  }, [isFetching]);
  useEffect(() => {
    let data = [];
    if (!isCustomerDetailFetching) {
      if (customerDetail.data && customerDetail.data.length > 0) {
        data = customerDetail.data[0];
      }
      setCustomer(data);
    }
  }, [isCustomerDetailFetching]);

  return (
    <Page>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      ></Stack>
      <Card sx={{ p: 2 }}>
        <BackButton text={"Customer Information"} path="/cm-customer" />
        {customerDetail && (
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 2, sm: 3, md: 4 }}
            >
              <GridLabel
                label="ID"
                value={customer?.id}
                xs={12}
                sm={6}
                md={4}
              />
              <GridLabel
                label="Name"
                value={customer?.firstName ? customer?.firstName : ""}
                xs={12}
                sm={6}
                md={4}
              />
              <GridLabel
                xs={12}
                sm={6}
                md={4}
                label="Email"
                value={customer?.email}
              />
              <GridLabel
                xs={12}
                sm={6}
                md={4}
                label="Phone"
                value={customer?.phone}
              />
              <GridLabel
                xs={12}
                sm={6}
                md={4}
                label="ocpp_tag_pk"
                value={customer?.ocpp_tag_pk}
              />
              <GridLabel
                xs={12}
                sm={6}
                md={4}
                label="Created At"
                value={fDateTime(customer?.createdAt, "yyyy-MM-dd hh:mm a")}
              />
            </Grid>
          </Box>
        )}
      </Card>
      <Card sx={{ p: 2, mt: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Wallet History
        </Typography>

        <BaseTable
          rows={tableData ?? []}
          columns={WalletHistoryListColumns(isReadOnly)}
          loading={isFetching}
          getRowId={() => crypto.randomUUID()}
          pageSize={10}
          csvMorphemes="ev_drivers"
          isReadOnly={isReadOnly}
        />
      </Card>
    </Page>
  );
}
