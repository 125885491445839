import { useMemo } from "react";
import { useSelectedTeam } from "./useSelectedTeam";

export const useAccess = (featureName, requiredAccess) => {
  const team = useSelectedTeam();
  const hasAccess = useMemo(() => {
    const access = team?.access?.find(
      (item) => item.featureName === featureName
    );
    switch (requiredAccess) {
      case "V":
        return access?.view === "Y";
      case "C":
        return access?.create === "Y";
      case "U":
        return access?.update === "Y";
      case "D":
        return access?.delete === "Y";
      case "R":
        return access?.view === "Y" && access?.update === "N" && access?.create === "N";
      case "N":
        return access?.view === "N" && access?.update === "N";
      case "FA":
        return access?.view === "Y" && access?.create === "Y" && access?.update === "Y" && access?.delete === "Y";
      case "RP":
          return access?.view === "Y";
      default:
        return false;
    }
  }, [team, featureName, requiredAccess]);
  return hasAccess;
};
