import { Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import ServiceRequestActionMenu from "./ServiceRequestActionMenu";



export const serviceRequestListColumns = (isReadOnly)=>([

    {
        field: "ticket_id",
        headerName: "Ticket ID",
        flex: 1,
        renderCell: ({value,row:{ticket_id}}) =>(
        <>
        {isReadOnly?value:<Link underline="none" component={RouterLink} to={`edit/${ticket_id}`}>{value}</Link>}
        </>
        )
    },
    {
        field: "email",
        headerName: "Email",
        flex: 1,

    },
    {
        field: "phone",
        headerName: "Phone No.",
        flex: 1,

    },
    {
        field: "issueType",
        headerName: "Issue Type",
        flex: 1,

    },
    {
        field: "description",
        headerName: "Description",
        flex: 1,

    },
    {
        field: "createAt",
        headerName: "Date/Time",
        flex: 1,
        type: "date",
        renderCell: ({ value }) => (
            <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
                <span>{yearFirstDateTimeFormat(value)}</span>
            </Tooltip>
        )
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
    },
    {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: ServiceRequestActionMenu
    },
])