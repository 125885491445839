// material
import { useMatch, useNavigate } from "react-router-dom";
import Page from "../Page";
import UserForm from "./UserForm";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { alertSlice } from "src/store/alert/alert.slice";

export default function User() {
  const newUserMatch = useMatch("/users/new");
  const navigate = useNavigate();
  const isReadOnly = useAccess(FEATURE.USERS, "R");
  const notAuthorized = useAccess(FEATURE.USERS, "N");
  const createAccess = useAccess(FEATURE.USERS, "C");
  const dispatch = useDispatch();

  useEffect(() => {
    if ((newUserMatch && !createAccess) || (!newUserMatch && notAuthorized)) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate("/users", { replace: true });
    }
  }, [dispatch, newUserMatch, createAccess, navigate, notAuthorized]);

  return (
    <Page >
      <BackButton
        text={
          newUserMatch
            ? "Create new user"
            : isReadOnly
            ? "User Details"
            : "Edit user details"
        }
        path="/users"
      />

      <UserForm />
    </Page>
  );
}
