import { fDateTime } from "src/utils/formatTime";

const dateFormat = "yyyy-MM-dd hh:mm a";

export const WalletHistoryListColumns = () => [
  {
    field: "id_tag",
    headerName: "Tag Id",
    flex: 0.9,
  },
  {
    field: "amount_changed",
    headerName: "Charged amount",
    flex: 1.2,
  },
  {
    field: "current_balance",
    headerName: "Balance",
    flex: 1,
  },
  {
    field: "note",
    headerName: "Remarks",
    flex: 1,
  },
  {
    field: "modify_time",
    headerName: "Created on",
    type: "date",
    flex: 1,
    valueFormatter: ({ value }) => {
      return fDateTime(value, dateFormat);
    },
  },
];
