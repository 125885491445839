// react
import { useCallback, useEffect, useRef, useState } from "react";
// mui components
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
//google map react
import GoogleMap from "google-map-react";
//custom component, utils
import { is } from "src/util/is";
//images
import ChargerMilesMarker from "../../image/chargemiles.svg";

const MAP_STYLE = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];

function Map({ onPlaces, lat, lng, address, searchBar = true, data = null }) {
  const [map, setMap] = useState(null);
  let markers = useRef([]);
  const onSearchBar = useCallback((map) => {
    let markers = [];
    const {
      places: { SearchBox },
      LatLngBounds,
      Size,
      Point,
      Marker,
      LatLng,
    } = window.google.maps;
    const input = document.getElementById("places");
    const searchBox = new SearchBox(input);
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    map.addListener("center_changed", () => {
      map.setZoom(14);
    });

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      const {
        geometry: { location },
      } = places[0];
      const center = new LatLng(location.lat(), location.lng());
      const bounds = new LatLngBounds();
      if (!places.length) {
        return;
      }
      // clear old markers
      markers.forEach((marker) => marker.setMap(null));
      markers = [];
      places.forEach((place) => {
        if (!place.geometry && !place.geometry.location) {
          return;
        }
        const icon = {
          url: place.icon,
          size: new Size(71, 71),
          origin: new Point(0, 0),
          anchor: new Point(17, 34),
          scaledSize: new Size(28, 28),
        };
        markers.push(
          new Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
        );
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.viewport);
        }
      });
      map.fitBounds(bounds);
      map.setCenter(center);
      onPlaces(places);
    });
    setMap(map);
  }, []);

  const onMarker = useCallback(
    (map) => {
      if (data && map && window.google) {
        const { LatLng, Marker, Size, Point, InfoWindow } = window.google.maps;
        if (markers.current.length) {
          markers.current.forEach((marker) => {
            return marker.setMap(null);
          });
          markers.current = [];
        }
        data.forEach((d) => {
          const latLng = new LatLng(
            Number(d.locationLatitude),
            Number(d.locationLongitude)
          );
          const infoWindow = new InfoWindow({
            content: `<div class="address_component">${d.street}</div>`,
            ariaLabel: "address",
          });
          const marker = new Marker({
            position: latLng,
            icon: {
              url: ChargerMilesMarker,
              size: new Size(71, 71),
              origin: new Point(0, 0),
              anchor: new Point(17, 34),
              scaledSize: new Size(28, 28),
            },
            map,
          });
          markers.current.push(marker);
          marker.addListener("mouseover", () => {
            infoWindow.open({
              anchor: marker,
              map,
            });
          });

          marker.addListener("mouseout", () => {
            infoWindow.close();
          });
        });
        map.addListener("center_changed", () => {
          map.setZoom(4);
        });
        map.setOptions({ styles: MAP_STYLE });
        map.setCenter(new LatLng(25.6663075, 78.4528179));
      }
    },
    [data, markers]
  );

  const handleMapApiLoaded = useCallback(({ map }) => {
    if (window.google) {
      onSearchBar(map);
    }
  }, []);

  useEffect(() => {
    if (lat && lng && !is.empty(address) && map && !data) {
      const { LatLng, Marker } = window.google.maps;
      const input = document.getElementById("places");
      const position = new LatLng(lat, lng);
      input && (input.value = address);
      map.setCenter(position);
      if (markers?.current?.length) {  
        markers.current.forEach((marker) => {
          return marker.setMap(null);
        });
        markers.current = [];
      }
      markers.current.push(
        new Marker({
          position,
          map,
        })
      );
    }

    if (data) {
      onMarker(map);
    }
  }, [lat, lng, address, map, data]);

  return (
    <Stack direction="column" spacing={2}>
      {searchBar && (
        <FormControl fullWidth size="small">
          <TextField
            label="Search Place"
            InputLabelProps={{ shrink: true }}
            size="small"
            id="places"
            placeholder="Address"
          />
        </FormControl>
      )}
      <div className="map-container">
        <GoogleMap
          bootstrapURLKeys={{
            key: "AIzaSyDUgxJLnLjZm3fV_24nDDB-2BKMNqmra9I",
            libraries: ["places"],
          }}
          defaultCenter={{
            lat: 10.99835602,
            lng: 77.01502627,
          }}
          defaultZoom={10}
          onGoogleApiLoaded={handleMapApiLoaded}
        />
      </div>
    </Stack>
  );
}

Map.defaultProps = {
  onPlaces: () => {},
  lat: 0,
  lng: 0,
  address: "",
};

export default Map;
