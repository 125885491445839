import { fDate } from "./formatTime";
import * as XLSX from "xlsx";

export const changeToCSV = ({ headers, properties, valuesObjArr }) => {
  let csv = headers.join(",") + "\n";
  valuesObjArr.forEach((ValuesObj) => {
    properties.forEach((property, index) => {
      const value = ValuesObj[property] ?? "";
      csv += value;
      if (index < properties.length - 1) {
        csv += ",";
      }
    });
    csv += "\n";
  });
  return csv;
};

export const saveToExcel = (
  { headers, properties, valuesObjArr },
  fileName
) => {
    let worksheet = [];
    valuesObjArr.forEach((valuesObj) =>{
        let obj={};
            properties.forEach((property, index) =>{
            if(headers[index] && property !== "totalKw"){
                obj[headers[index]] = valuesObj[property];
            }else if(property === "totalKw"){
               let totalKw = (valuesObj.stop_meter_value - valuesObj.start_meter_value)/1000;
               obj["Total Kw Consumed"] = totalKw;
            }
        })
        worksheet.push(obj)
    });
    const ws = XLSX.utils.json_to_sheet(worksheet);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const downloadCSV = (csv, csvMorphemes) => {
  const blob = new Blob([csv], { type: "text/csv" });
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = `${csvMorphemes}.csv`;
  a.click();
};
