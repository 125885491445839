import { Card, Stack } from "@mui/material";
import { FEATURE } from "src/util/enums";
import { useAccess } from "src/hooks/useAccess.hook";
import { useList } from "src/hooks/useFetch.hook";
import Page from "../Page";
import { BaseTable } from "../BaseTable/BaseTable";
import { chargerPointColumns } from "./charger.constants";
import { useDispatch, useSelector } from "react-redux";
import { dialogSlice } from "src/store/dailog/dailog.slice";
import { RemoteOperationModal } from "./RemoteOperation/RemoteOperationModal";
import { ViewLogsModal } from "./ViewLogsModal";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RemoteOpsStatusModal from "./RemoteOperation/RemoteOpsStatusModal";
import { remoteOpsStatusSlice } from "src/store/remoteOpsStatus/remoteOpsStatus.slice";
import { remoteOperations } from "./RemoteOperation/remote.constants";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import AddNewBtn from "../AddNewBtn";
import ExportCSV from "../ExportCsv/ExportCSV";

export default function ChargerList() {
  const hasCreateAccess = useAccess(FEATURE.CHARGER, "C");
  const isReadOnly = useAccess(FEATURE.CHARGER, "R");
  const { data: chargers, isFetching } = useList("chargers");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open, data } = useSelector(({ dialog }) => dialog);
  const {modalOpen,remoteOperationPerformed,operationDetails} = useSelector(({remoteOpStatus})=>remoteOpStatus)
  const [sortModel,setSortModel] = useState([{field:"status",sort:"asc"}]);
  const [tableData, setTableData] = useState();
  const handleClose = () => {
    dispatch(dialogSlice.actions.setDialog(false));
  };

  useEffect(() => {
    if (!isFetching){
      let data = [...chargers];
      setTableData(data);
    }
  }, [isFetching]);

  const handleRemoteOpsStatusModalClose =()=>{
  dispatch(remoteOpsStatusSlice.actions.setRemoteOps({open:false,remoteOperationPerformed:null,operationDetails:{chargerId:null,response:null}}));
    
  }
  const rowOrCellClickRedirect = ({ id,row}) => {
    if(isReadOnly) return;
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId:row?.chargepoint_id,
      tabOpenedFor:"charger",
      tabOpened:"general"
    }))
    navigate(`/charger/${id}`);
  };

  const addNewChargerBtnHandler =()=> dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
    detailsId:"",
    tabOpenedFor:"charger",
    tabOpened:"general"
  }))

  const handleSearching = (event) => {
    const dataToSearchFrom = chargers;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "chargepoint_id",
            "team_name",
            "street",
            "description",
            "visibility",
            "status",
            "lastHeartbeatTimestamp",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "lastHeartbeatTimestamp"
                ? yearFirstDateTimeFormat(value).toString()
                :  value === "Y" ? "active" : value === "N" ? "inactive" : value === 1 ? "public" : value === 0 ? "private" : value ?  value.toString().toLowerCase() : ""
                );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...chargers]);
  };
  
  return (
    <Page
      
      {...(hasCreateAccess && { path: "new",action: addNewChargerBtnHandler  })}
      actionButtonText="Add Charge Point"
    >
      <Card sx={{ p: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
      </Stack>
        <BaseTable
          rows={tableData ?? []}
          columns={chargerPointColumns(isReadOnly)}
          loading={isFetching}
          getRowId={(row) => row.chargepoint_pk}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          pageSize={10}
          sortModel={sortModel}
          onSortModelChange={(model)=>setSortModel(model)}
          isReadOnly={isReadOnly}
          showExportCsvBtn={true}
          csvMorphemes="chargers"
          csvColumns = {chargerPointColumns(isReadOnly)}
        />
      </Card>
      {open && data?.modalType === "remoteoperation" && (
        <RemoteOperationModal
          open={open}
          chargerId={data?.id}
          chargerPk={data?.pk}
          onClose={handleClose}
        />
      )}
      {/* {
        modalOpen && remoteOperationPerformed && (
          <RemoteOpsStatusModal
          open={modalOpen}
           onClose={handleRemoteOpsStatusModalClose}
           operation={remoteOperations.find(operation=>operation.value===remoteOperationPerformed).label}
           responseRow={operationDetails.response? [{
              id:1,
              charge_box_id:operationDetails.chargerId,
              response:operationDetails.response.response,
              error:operationDetails.response.errorMessage,
              details:operationDetails.response.details
            }]:[{
              id:1,
              charge_box_id:operationDetails.chargerId,
              response:"",
              error:"",
              details:""
            }]
           }
          />
        )
      }
      {open && data?.modalType === "viewlogs" && (
        <ViewLogsModal open={open} chargerId={data?.id} onClose={handleClose} />
      )} */}
    </Page>
  );
}
