import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { TabContext, TabList } from "@mui/lab";
import { Card, Box, Button, Stack, Typography, TextField, Radio, RadioGroup, FormControlLabel, NativeSelect } from "@mui/material";
import Page from "src/components/Page";
import { RootTabItemStyles } from 'src/theme/overrides/Tabs';
import { createChargeZone, deleteChargeZoneById, getAvailableCharger, getChargeZoneById, getChargeZoneList, updateChargeZoneById } from 'src/react-query/endPoints';
import axios from '../../util/axios';
import { useSelectedTeam } from 'src/hooks/useSelectedTeam';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ReactComponent as ZoneLogo } from "../../image/zoneLogo.svg"
import { BaseTable } from '../BaseTable/BaseTable';
import { useAccess } from 'src/hooks/useAccess.hook';
import enums, { FEATURE } from 'src/util/enums';
import { availableChargerColumn, chargeZoneColumn, chargeZoneTab } from './ChargeZoneList.constant';
import { CommonSearchbar } from 'src/layouts/dashboard/Searchbar';
import { yearFirstDateTimeFormat } from 'src/utils/formatTime';
import { Icon } from '@iconify/react';
import plusFill from "@iconify/icons-eva/plus-fill";
import InputAdornment from "@mui/material/InputAdornment";
import { alertSlice } from 'src/store/alert/alert.slice';
import { useDispatch } from "react-redux";
import { ReactComponent as ChargerLogo } from "../../image/charger-station.svg"
import { ReactComponent as PowerLogo } from "../../image/power-logo.svg"

const {
    actions: { setAlert },
} = alertSlice;

const BoxStyle = styled('div')(({ theme }) => ({
    width: "100%",
    border: "1px solid #00000036",
    placeItems: "center",
    padding: "1rem",
    borderRadius: "10px"
}));

const RootTabStyle = styled(TabList)(({ theme, length }) => ({
    width: '98%',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    '& .MuiTabs-flexContainer': {
        justifyContent: 'left',
    }
}));

const ChargeZone = () => {
    const [selectedTab, setSelectedTab] = useState("CHARGING ZONE");
    const tabsToShow = chargeZoneTab;
    const { teamId } = useSelectedTeam();
    const [availableCharger, setAvailableCharger] = useState();
    const [chargerList, setChargerList] = useState();
    const [tableData, setTableData] = useState();
    const [createZoneBtn, setCreateZoneBtn] = useState(false);
    const [updateChargeZone, setUpdateChargeZone] = useState({ value: false, zoneId: "" });
    const isReadOnly = useAccess(FEATURE.CHARGEZONE, "R");
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        zoneName: '',
        isLoadBalancingReq: 0,
        loadBalancingUnit: "Kwh",
        loadBalancingValue: "",
        chargers: [],
    });

    const errorNotification = (err) => {
        return dispatch(
            setAlert({
                open: true,
                message: err.message,
                severity: enums.SEVERITY.ERROR,
            })
        );
    };

    const handleAction = async ({ action, id }) => {
        if (action) {
            try {
                const response = await axios.delete(deleteChargeZoneById(teamId, id));
                if (response.data.success) {
                    dispatch(
                        setAlert({
                            open: true,
                            message: "Zone delete successfully",
                            severity: enums.SEVERITY.SUCCESS,
                        })
                    );
                    getChargerList();
                }
            } catch (err) {
                errorNotification(err)
            }
        } else {
            getChargerZoneById(id);
            setUpdateChargeZone({ value: true, zoneId: id });
        }
    };

    const getChargerZoneById = async (zoneId) => {
        try {
            const response = await (axios.get(getChargeZoneById(teamId, zoneId)));
            const updatedFormData = { ...formData, zoneName: response.data.data.zoneName, isLoadBalancingReq: response.data.data.isLoadBalancingReq, loadBalancingUnit: response.data.data.loadBalancingUnit, loadBalancingValue: response.data.data.loadBalancingValue, chargers: response.data.data.chargers };
            setAvailableCharger(response.data.data.chargers)
            setTableData(response.data.data.chargers)
            setFormData(updatedFormData);
        } catch (err) {
            console.log(err)
        }
    }

    const handleSelectionChange = (selectionModel) => {
        const selectedCharger = selectionModel.filter((data) => data.selected === true);
        setFormData(((props) => ({ ...props, chargers: selectedCharger })));
    };

    const chargeZoneColumns = chargeZoneColumn({ createZoneBtn, setCreateZoneBtn, handleAction });
    const availableChargerColumns = tableData && availableChargerColumn({ handleSelectionChange, tableData });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        if (updateChargeZone.value) {
            try {
                const response = await axios.post(updateChargeZoneById(teamId, updateChargeZone.zoneId), formData);
                if (response.data.success) {
                    dispatch(
                        setAlert({
                            open: true,
                            message: "Zone update successfully",
                            severity: enums.SEVERITY.SUCCESS,
                        })
                    );
                    getChargerList();
                    setUpdateChargeZone({ value: false, zoneId: "" });
                    setCreateZoneBtn(false);
                }
            } catch (err) {
                errorNotification(err)
                console.log(err)
            }
        } else {
            try {
                const response = await axios.post(createChargeZone(teamId), formData);
                if (response.data.success) {
                    dispatch(
                        setAlert({
                            open: true,
                            message: "Zone create successfully",
                            severity: enums.SEVERITY.SUCCESS,
                        })
                    );
                    getChargerList();
                    setCreateZoneBtn(false);
                }
            } catch (err) {
                errorNotification(err)
            }
        }
    };

    const getChargerList = async () => {
        try {
            const response = await axios.get(getChargeZoneList(teamId));
            setChargerList(response.data.data)
            getAvailableChargerList(response.data.data);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getChargerList();
    }, [teamId])

    const getAvailableChargerList = async (chargerList) => {
        try {
            const response = await axios.get(getAvailableCharger(teamId));
            const filtered = response.data.data.chargers.filter((data) => chargerList.filter((list) => !list.chargers.includes(data.chargerId)))
            setAvailableCharger(filtered)
            setTableData(filtered)
        } catch (err) {
            console.log(err)
        }
    }

    const handleTabChange = (e, tab) => {
        setSelectedTab(tab);
    }

    const handleSearching = (event) => {
        const dataToSearchFrom = availableCharger;
        const searchValue = event.target.value.trim().toString().toLowerCase();
        if (searchValue) {
            const filteredDataArr = dataToSearchFrom.reduce(
                (filteredDataArr, currentObj) => {
                    const relevantFields = [
                        "chargerId",
                    ];
                    const allValuesArr = Object.entries(currentObj)
                        .filter(([key, value]) => relevantFields.includes(key))
                        .map(([key, value]) =>
                            key === "starttime" || key === "stoptime"
                                ? yearFirstDateTimeFormat(value).toString()
                                : value ? value.toString().toLowerCase() : ""
                        );
                    const searchResult = allValuesArr.find((value) =>
                        value.includes(searchValue)
                    );
                    if (searchResult) {
                        filteredDataArr = [...filteredDataArr, currentObj];
                    }
                    return filteredDataArr;
                },
                []
            );
            setTableData(filteredDataArr);
        } else setTableData([...availableCharger]);
    };

    const handleCreateZone = () => {
        setCreateZoneBtn(true);
        setFormData({
            zoneName: '',
            isLoadBalancingReq: 0,
            loadBalancingUnit: "Kwh",
            loadBalancingValue: "",
            chargers: [],
        });
    }

    return (
        <Page >
            <TabContext value={selectedTab}>
                <Stack direction="row" justifyContent="center" alignItem="center" sx={{ width: "100%" }}>
                    <RootTabStyle
                        indicatorColor="transparent"
                        centered
                        length={tabsToShow.length}
                        onChange={handleTabChange}
                    >
                        {
                            tabsToShow.map((tab) => <RootTabItemStyles value={tab.value} label={tab.label} />)
                        }
                    </RootTabStyle>
                </Stack >
                <Card sx={{ p: 4 }}>
                    <Stack direction="flex" sx={{ gap: "4rem" }}>
                        <BoxStyle>
                            <Box sx={{ display: "flex", placeItems: 'center', placeContent: "center", gap: '2rem' }}>
                                <ZoneLogo />
                                <Box sx={{ display: "flex", flexDirection: "column", placeItems: "center" }}>
                                    <Typography mb={1} sx={{ fontWeight: "600", fontSize: "16px" }}>Total Zones</Typography>
                                    <Typography sx={{ color: "#00AB55", display: "inline-block", fontWeight: "600", fontSize: "2rem" }}>{chargerList?.length}</Typography>
                                </Box>
                            </Box>
                        </BoxStyle>
                        <BoxStyle>
                            <Box sx={{ display: "flex", flexDirection: "column", placeItems: "center" }}>
                                <Typography mb={3} sx={{ fontWeight: "600", fontSize: "16px" }}>Zone Wise Charging Point</Typography>
                            </Box>
                            <Box sx={{ display: "flex", placeContent: 'space-between', borderBottom: "2px solid #00000036" }}>
                                <p>Testzone</p>
                                <Box mb={2} sx={{ display: "flex" }}><ChargerLogo /> <Typography ml={1}>3</Typography></Box>
                            </Box>
                        </BoxStyle>
                        <BoxStyle>
                            <Box sx={{ display: "flex", flexDirection: "column", placeItems: "center" }}>
                                <Typography mb={3} sx={{ fontWeight: "600", fontSize: "16px" }}>Zone Wise Charging Point</Typography>
                            </Box>
                            <Box sx={{ display: "flex", placeContent: 'space-between', borderBottom: "2px solid #00000036" }}>
                                <p>Testzone</p>
                                <Box mb={2} sx={{ display: "flex" }}><Typography mr={1}>13.12 KwH</Typography><PowerLogo /></Box>
                            </Box>
                        </BoxStyle>

                    </Stack>
                    <Box>
                        <Typography mt={4} sx={{ borderBottom: "2px solid #00000036", paddingBottom: "12px" }}>CHARGER ZONE</Typography>
                        <Stack direction="flex" mt={2} mb={4} sx={{ gap: "1rem", alignItems: "center" }}>
                            <Box sx={{ position: "relative" }}>
                                <Button variant="contained" onClick={handleCreateZone}>
                                    <Icon icon={plusFill} /> <Typography ml={1}>Create Zone</Typography>
                                </Button>
                                {createZoneBtn && <Dialog
                                    onClose={() => { setCreateZoneBtn(false) }}
                                    aria-labelledby="customized-dialog-title"
                                    open={createZoneBtn}
                                >
                                    <DialogContent>
                                        <form onSubmit={handleSubmitForm}>
                                            <Box sx={{ minHeight: "500px", minWidth: "400px", display: "flex", flexDirection: "column", gap: "1rem" }} >
                                                <div>
                                                    <Typography sx={{ mb: 2, fontWeight: "600", fontSize: "16px" }}>Create Zone</Typography>
                                                </div>
                                                <Box>
                                                    <TextField sx={{ width: "100%" }} id="outlined-basic" label="Zone Name" variant="outlined" name="zoneName"
                                                        value={formData.zoneName}
                                                        onChange={handleChange} />
                                                </Box>
                                                <Box sx={{ display: "flex", gap: "1rem", placeItems: "center" }}>
                                                    <Typography>Load Balance: </Typography>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        // defaultValue="yes"
                                                        name="isLoadBalancingReq"
                                                        sx={{ flexDirection: "row" }}
                                                        value={formData.isLoadBalancingReq}
                                                        onChange={handleChange}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={0} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </Box>
                                                <Box>
                                                    <TextField sx={{ width: "100%" }} label="Load Limit" variant="outlined" name="loadBalancingValue"
                                                        value={formData.loadBalancingValue}
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <NativeSelect
                                                                        defaultValue={30}
                                                                        inputProps={{
                                                                            name: 'loadBalancingUnit',
                                                                            id: 'uncontrolled-native',
                                                                        }}
                                                                        value={formData.loadBalancingUnit}
                                                                        onChange={handleChange}
                                                                        sx={{ width: "100px" }}
                                                                    >
                                                                        <option value="Kwh">Kwh</option>
                                                                        <option value="Amp">Amp</option>
                                                                    </NativeSelect>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{ border: "1px solid #0000003B", borderRadius: "8px" }}>
                                                    <CommonSearchbar
                                                        placeholder="Search"
                                                        searchFunc={(e) => handleSearching(e)}
                                                    />
                                                    <Box sx={{
                                                        overflow: "hidden", height: "200px", overflowY: "auto"}}>
                                                    {tableData?.length > 0 && <BaseTable
                                                        isReadOnly={true}
                                                        rows={tableData ?? []}
                                                        columns={availableChargerColumns}
                                                        loading={false}
                                                        getRowId={(row) => row.chargerId}
                                                        pageSize={10}
                                                        showExportCsvBtn={false}
                                                        onSelectionModelChange={handleSelectionChange}
                                                        sx={{
                                                            overflow: "hidden", height: "200px", overflowY: "auto",
                                                            ".MuiDataGrid-columnHeaderTitle": {
                                                                overflow: "hidden   !important",
                                                                lineHeight: "20px   !important",
                                                                whiteSpace: "normal  !important",
                                                            },
                                                        }}
                                                    />}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", placeContent: "end", gap: "1rem" }}>
                                                <Button type="submit" variant="contained">
                                                    Create
                                                </Button>
                                                <Button variant="contained" onClick={() => setCreateZoneBtn(false)}>
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </Box>
                                    </form>
                                </DialogContent>
                                </Dialog>}
                    </Box>
                </Stack>
            </Box>
            <Box>
                {chargerList?.length > 0 && <BaseTable
                    isReadOnly={isReadOnly}
                    rows={chargerList ?? []}
                    columns={chargeZoneColumns}
                    loading={false}
                    getRowId={(row) => row.zone_id}
                    pageSize={10}
                    showExportCsvBtn={false}
                    sx={{
                        ".MuiDataGrid-columnHeaderTitle": {
                            overflow: "hidden   !important",
                            lineHeight: "20px   !important",
                            whiteSpace: "normal  !important",
                        },
                    }}
                />}
            </Box>
        </Card>
            </TabContext >
        </Page >
    )
}

export default ChargeZone
