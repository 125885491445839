import { Card, Stack } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddNewBtn from 'src/components/AddNewBtn'
import { BaseTable } from 'src/components/BaseTable/BaseTable'
import Page from 'src/components/Page'
import { useAccess } from 'src/hooks/useAccess.hook'
import { FEATURE } from 'src/util/enums'
import { chargingprofileListColumn, chargingprofileListRows } from '../ChargingProfile.constants'
import { useChargingProfileList } from '../ChargingProfile.hook'
import { CommonSearchbar } from 'src/layouts/dashboard/Searchbar'
import { yearFirstDateTimeFormat } from 'src/utils/formatTime'

const ChargingProfileList = () => {
  const navigate = useNavigate()
  // const hasCreateAccess = useAccess(FEATURE.CHARGERPROFILE,"C");
  const isReadOnly = useAccess(FEATURE.CHARGERPROFILE, "R");
  const hasCreateAccess = true;
const {data:chargingProfileList,isFetching} = useChargingProfileList();
const [tableData, setTableData] = useState();
  const rowOrCellClickRedirect=({id})=>{
    if(isReadOnly) return;  
    navigate(`${id}`)
  };

  useEffect(() => {
    if (!isFetching){
      let data = [...chargingProfileList];
      setTableData(data);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = chargingProfileList;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "charging_profile_id",
            "stack_level",
            "description",
            "charging_profile_purpose",
            "charging_profile_kind",
            "recurrency_kind",
            "valid_from",
            "valid_to",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "valid_from" || key === "valid_to"
                ? yearFirstDateTimeFormat(value).toString()
                : value ? value.toString().toLowerCase() : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...chargingProfileList]);
  };

  return (
    <>
    {hasCreateAccess &&
     <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      marginBottom={2}
     >
      <AddNewBtn to="/chargingprofile/new" label="Add new charging profile"/>
     </Stack>
     }
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
      </Stack>
      <Card sx={{p:2}}>
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
     <BaseTable
     loading={isFetching}
     rows={tableData??[]}
     columns={chargingprofileListColumn(isReadOnly)}
     pageSize={10}
     getRowId={(row)=>row.charging_profile_pk}
     rowOrCellClickRedirect={rowOrCellClickRedirect}
     isReadOnly={isReadOnly}
     showExportCsvBtn={true}
     csvMorphemes="charging-profile"
     csvColumns = {chargingprofileListColumn(isReadOnly)}
     />
    </Card>
    </>
  )
}


export default ChargingProfileList