import Page from "../Page";
import { useAccess } from "src/hooks/useAccess.hook";
import { Card } from "@mui/material";
import { BaseTable } from "../BaseTable/BaseTable";
import { useList } from "src/hooks/useFetch.hook";
import { FEATURE } from "src/util/enums";
import { companyListColumns } from "./company.constants";
import { useNavigate } from "react-router-dom";

export const CompanyList = () => {
  const navigate = useNavigate()
  const hasCreateAccess = useAccess(FEATURE.COMPANY, "C");
  const isReadOnly = useAccess(FEATURE.COMPANY, "R");
  const { data: company, isFetching } = useList("companyList");
  const rowOrCellClickRedirect = ({ id }) => {
    if(isReadOnly) return;
    navigate(`/company/${id}`);
  };
  return (
    <Page

      {...(hasCreateAccess && { path: `/company/new` })}
      actionButtonText="Add Company"
    >
      <Card sx={{ p: 2 }}>
        <BaseTable
          rows={company ?? []}
          columns={companyListColumns(isReadOnly)}
          loading={isFetching}
          getRowId={(row) => row.teamId}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          isReadOnly={isReadOnly}
        />
      </Card>
    </Page>
  );
};
