import {
  categoryUrl,
  chargersUrl,
  teamsUrl,
  userTypeUrl,
  userUrl,
  transactionsUrl,
  getTeamUrl,
  transactionIdUrl,
  meterValueUrl,
  tagsUrl,
  tagsForRemoteOpUrl,
  availableTagsUrl,
  tagIdUrl,
  chargerIdUrl,
  priceGroupsUrl,
  priceGroupIdUrl,
  teamUrlId,
  teamUsersUrl,
  getUserDetailUrl,
  usersUrl,
  getUserProfile,
  rolesUrl,
  chargerTransactions,
  tagTransactions,
  companyUrl,
  getRoleUrlId,
  featureUrl,
  getCompanyUrlId,
  viewLogsUrl,
  getConnectorsUrl,
  getTagsByChargerIdUrl,
  customerUrl,
  getCustomerById,
  customerTagsById,
  tagChargerUrl,
  chargerActiveTransactions,
  tagUserUrl,
  serviceRequestUrl,
  getServiceRequestDetailsUrl,
  chargingProfileListUrl,
  chargingProfileDetailsUrl,
  refundUrl,
  getSettlementPercentageUrl,
  getPaymentDetailsForPriceGroupUrl,
  getPaymentDetailsOfCompany,
  cmCustomerUrl,
  availablePaymentGatewayListUrl,
  walletHistoryUrl,
  teamPayoutUrl,
} from "./endPoints";

export const queryKeys = {
  //   authenticate: (chargerId) => {
  //     return ["charger", { method: "GET", url: chargerUrl(chargerId) }];
  //   },
  user: () => ["user", { url: userUrl }],
  users: () => ["users", { url: usersUrl }],
  roles: () => ["roles", { url: rolesUrl }],
  role: (teamId, id) => ["getRole", { url: getRoleUrlId(id) }],
  usersByTeam: (_, id) => [
    "usersByTeam",
    { url: getTeamUrl(id, teamUsersUrl) },
  ],
  settlementPercentage: [
    "getSettlementPercentage",
    { url: getSettlementPercentageUrl },
  ],
  paymentDetailsForPriceGroup: (teamId) => [
    "getPaymentDetailsForPriceGroup",
    { url: getPaymentDetailsForPriceGroupUrl(teamId) },
  ],
  paymentDetailsOfCompany: (companyId) => [
    "getPaymentDetailsOfCompany",
    { url: getPaymentDetailsOfCompany(companyId) },
  ],
  setupCatgegory: (body) => ["setupCatgegory", { url: categoryUrl, body }],
  setupUserType: (body) => ["setupUserType", { url: userTypeUrl, body }],
  chargers: (teamId) => [
    "getChargers",
    { url: getTeamUrl(teamId, chargersUrl) },
  ],
  charger: (teamId, chargerId) => [
    "getCharger",
    { url: getTeamUrl(teamId, chargerIdUrl(chargerId)) },
  ],
  generateQR: (teamId, chargerId) => [
    "generateQR",
    { url: getTeamUrl(teamId, `/charger/${chargerId}/generateQr`) },
  ],
  transactions: (teamId) => [
    "getTransactions",
    { url: getTeamUrl(teamId, transactionsUrl) },
  ],
  transactionStatus: (teamId, transactionId) => [
    "transactionStatus",
    { url: getTeamUrl(teamId, `/${transactionIdUrl(transactionId)}/status`) },
  ],
  chargerTransactions: (teamId, chargerId) => [
    "getTransactions",
    { url: getTeamUrl(teamId, chargerTransactions(chargerId)) },
  ],
  chargerActiveTransactions: (teamId, chargerId) => [
    "getActiveTransactions",
    { url: getTeamUrl(teamId, chargerActiveTransactions(chargerId)) },
  ],
  tagTransactions: (teamId, tagId) => [
    "getTransactions",
    { url: getTeamUrl(teamId, tagTransactions(tagId)) },
  ],
  payoutTransactions: (teamId, payoutId) => [
    "getTransactions",
    { url: getTeamUrl(teamId, `${transactionsUrl}?payoutId=${payoutId}`) },
  ],
  customerTransactions: (teamId, {id,email,phone,domain,ocpp_tag_pk}) => [
    "getTransactions",
    { url: getTeamUrl(teamId, `${transactionsUrl}?id=${id}&email=${email}&phone=${phone}&domain=${domain}&ocpp_tag_pk=${ocpp_tag_pk}`) },
  ],
  transaction: (teamId, transactionId) => [
    "getTransaction",
    { url: getTeamUrl(teamId, transactionIdUrl(transactionId)) },
  ],
  transactionMeterValue: (teamId, transactionId, connectorId) => [
    "getMeterValue",
    {
      url: getTeamUrl(
        teamId,
        meterValueUrl(transactionIdUrl(transactionId), connectorId)
      ),
    },
  ],
  serviceRequest: () => ["getServiceRequests", { url: serviceRequestUrl }],
  serviceRequestDetails: (id) => [
    "getServiceRequestDetails",
    { url: getServiceRequestDetailsUrl(id) },
  ],
  chargingProfileList: () => [
    "getChargingProfileList",
    { url: chargingProfileListUrl() },
  ],
  chargingProfileDetails: (chargingProfileId) => [
    "getChargingProfileDetails",
    { url: chargingProfileDetailsUrl(chargingProfileId) },
  ],
  refund: (teamId, tId = 0) => [
    "refund",
    { url: `${refundUrl}${tId ? "/" + tId : ""}` },
  ],
  tags: (teamId) => ["getTags", { url: getTeamUrl(teamId, tagsUrl) }],
  tagsForRemoteOp: (teamId, chargerPk, mail) => [
    "getTagsForRemoteOp",
    {
      url: getTeamUrl(teamId, tagsForRemoteOpUrl, chargerPk),
      method: "POST",
      body: { email: mail },
    },
  ],
  availableTags: (teamId, customerId) => [
    "availableTags",
    { url: getTeamUrl(teamId, availableTagsUrl, customerId) },
  ],
  tag: (teamId, tagId) => [
    "getTag",
    { url: getTeamUrl(teamId, tagIdUrl(tagId)) },
  ],
  tagsCharger: (teamId, tagId, chargerId = null) => [
    "getTagCharger",
    { url: getTeamUrl(teamId, tagChargerUrl(tagId, chargerId)) },
  ],
  tagsUser: (teamId, tagId) => [
    "getTagUser",
    { url: tagUserUrl(teamId, tagId) },
  ],
  priceGroups: (teamId) => [
    "getPriceGroup",
    { url: getTeamUrl(teamId, priceGroupsUrl) },
  ],
  priceGroup: (teamId, priceGroupId) => [
    "getPriceGroup",
    { url: getTeamUrl(teamId, priceGroupIdUrl(priceGroupId)) },
  ],
  teams: (parentTeamId) => ["getTeams", { url: teamsUrl(parentTeamId) }],

  team: (id) => ["getTeam", { url: teamUrlId(id) }],
  userDetail: (teamId, id) => ["getUser", { url: getUserDetailUrl(id) }],
  profile: () => ["getProfile", { url: getUserProfile() }],
  companyList: () => ["companyList", { url: companyUrl }],
  company: (teamId, id) => ["getCompany", { url: getCompanyUrlId(id) }],
  features: () => ["getFeatures", { url: featureUrl }],
  viewLogs: (teamId, chargerId) => [
    "viewLogs",
    { url: getTeamUrl(teamId, viewLogsUrl(chargerId)) },
  ],
  getConnectorsUrl: (teamId, chargerId) => [
    "getConnectorsUrl",
    { url: getTeamUrl(teamId, getConnectorsUrl(chargerId)) },
  ],
  getTagsByChargerId: (teamId, chargerId) => [
    "getTagsByChargerId",
    { url: getTeamUrl(teamId, getTagsByChargerIdUrl(chargerId)) },
  ],
  customers: () => ["customerList", { url: customerUrl }],
  customer: (t, id) => ["customer", { url: getCustomerById(id) }],
  customerTags: (t, id) => ["customerTags", { url: customerTagsById(id) }],
  cmCustomers: () => ["cmCustomerList", { url: cmCustomerUrl }],
  cmCustomerById: (id) => [
    "cmCustomerById",
    { url: cmCustomerUrl + `?customerId=${id}` },
  ],

  getPaymentGateway: () => [
    "getPaymentGateway",
    {
      url: availablePaymentGatewayListUrl,
      method: "GET",
    },
  ],
  walletHistoryList: (customerId) => [
    "",
    { url: walletHistoryUrl(customerId), method: "GET" },
  ],
  payoutList: (teamId) => ["", { url: teamPayoutUrl(teamId), method: "GET" }],
};
