import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import payoutFooter from "../../image/payoutFooterImg.png";
import payoutHeader from "../../image/payoutHeaderImg.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    // padding: 20,
  },
  parentView: {
    paddingHorizontal: 20,
    minHeight: "635px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Lato Bold",
    marginVertical: 20,
  },
  heading: {
    fontSize: 16,
    fontFamily: "Lato Bold",
    marginVertical: 20,
  },
  label: {
    fontSize: 14,
    fontWeight: "ultrabold",
  },
  text: {
    fontSize: 14,
    fontWeight: "thin",
  },
  textWrap: {
    fontSize: 14,
    flexWrap: "wrap",
    width: "250px",
  },
  address: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20",
  },
  dateSection: {
    minWidth: "400px",
    display: "flex",
    alignItems: "flex-end",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: "none",
  },
  bold: {
    fontFamily: "Lato Bold",
  },
  col1: {
    width: "20%",
    fontSize: 11,
  },
  col2: {
    width: "15%",
    fontSize: 11,
  },
  col3: {
    width: "15%",
    fontSize: 11,
  },
  col4: {
    width: "20%",
    fontSize: 11,
  },
  col5: {
    width: "27%",
    fontSize: 11,
  },
});

Font.register({
  family: "Lato Bold",
  src: "https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf",
});
export function LiTag({ label, text }) {
  return (
    <View style={{ flexDirection: "row", marginBottom: 4 }}>
      <Text style={{ marginHorizontal: 8 }}>•</Text>
      <Text style={styles.label}>{label}: </Text>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
}
export const PayoutPdf = ({ row }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View fixed>
        <Image src={payoutHeader} />
      </View>
      <View style={styles.parentView}>
        <Text style={styles.title}>SETTLEMENT DETAILS</Text>

        {/* cm address */}
        <View style={styles.address}>
          <View style={styles.addressSection}>
            <Text style={styles.label}>From:</Text>
            <Text style={styles.text}>AMIK Technologies</Text>
            <Text style={styles.text}>
              Floor 24, Tower B, Bhutani Alphathum
            </Text>
            <Text style={styles.text}>
              Sector 90, Noida, Uttar Pradesh 201305
            </Text>
            <Text style={styles.label}>GST NUMBER:</Text>
            <Text style={styles.text}>09ABACA1808N1ZL</Text>
          </View>
          <View style={styles.dateSection}>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.label}>Settlement Number: </Text>
              <Text style={styles.text}>{row?.payoutId}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.label}>From Date: </Text>
              <Text style={styles.text}>{row?.periodStartDate}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.label}>To Date: </Text>
              <Text style={styles.text}>{row?.displayEndDate}</Text>
            </View>
          </View>
        </View>
        {/* cpo address */}
        <View style={styles.address}>
          <View style={styles.addressSection}>
            <Text style={styles.label}>To: </Text>
            {row?.company?.payoutAccounts[0]?.beneficiary_name && (
              <Text style={styles.textWrap}>
                {row?.company?.payoutAccounts[0]?.beneficiary_name}
              </Text>
            )}

            <Text style={styles.textWrap}>{row.company?.street}</Text>
          </View>
        </View>
        <Text style={styles.heading}>
          Transaction Summary for Charging Stations:
        </Text>

        {/* table */}
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={[styles.col1, styles.bold]}>Date</Text>
            <Text style={[styles.col3, styles.bold]}>KW Charged</Text>
            <Text style={[styles.col4, styles.bold]}>
              Amount Charged To Customer
            </Text>
            <Text style={[styles.col5, styles.bold]}>
              Payment Gateway Charges
            </Text>
            <Text style={[styles.col5, styles.bold]}>AMIK Comission</Text>
            <Text style={[styles.col5, styles.bold]}>Amount Settled</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.col1}>{row?.periodStartDate}</Text>
            <Text style={styles.col3}>{row?.unitsConsumed}</Text>
            <Text style={styles.col4}>{row?.totalAmountCharged}</Text>
            <Text style={styles.col5}>{row?.paymentGatewayFee}</Text>
            <Text style={styles.col5}>{row?.amikComission}</Text>
            <Text style={styles.col5}>{row?.CPOPayout}</Text>
          </View>
        </View>
        {/* Transation summary */}

        <View>
          <Text style={styles.heading}>Total Transaction</Text>
          <View style={{ flexDirection: "column", width: 400 }}>
            <LiTag label={"Total Units Charged"} text={row?.unitsConsumed} />
            <LiTag
              label={"Total Amount Collected from Customer"}
              text={row?.totalAmountCharged}
            />
            <LiTag
              label={"Total Payment Gateway Charges"}
              text={row?.paymentGatewayFee}
            />
          </View>
        </View>
      </View>
      {/* footer */}
      <View fixed>
        <Image src={payoutFooter} />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View fixed>
        <Image src={payoutHeader} />
      </View>
      <View style={styles.parentView}>
        <Text style={styles.heading}>Total AMIK Commission</Text>
        <View style={{ flexDirection: "column", width: 400 }}>
          <LiTag label={"Per Unit Commission "} text={row?.perUnitCommission} />
          <LiTag
            label={"Total Charger Commission "}
            text={row?.amikFixedComission}
          />
        </View>
        <View style={{ flexDirection: "row", marginBottom: 4 }}>
          <Text>
            <Text style={styles.label}>
              AMIK Commission (whichever is higher): {" "}
            </Text>
            <Text style={styles.text}>{row?.amikComission}</Text>
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginBottom: 4 }}>
          <Text>
            <Text style={styles.label}>Final Settlement Amount: </Text>
            <Text style={styles.text}>{row?.CPOPayout}</Text>
          </Text>
        </View>
      </View>
      <View fixed>
        <Image src={payoutFooter} />
      </View>
    </Page>
  </Document>
);
