import { useAccess } from "src/hooks/useAccess.hook";
import editFill from "@iconify/icons-eva/edit-fill";
import listFill from "@iconify/icons-eva/list-fill";
import disableFill from "@iconify/icons-eva/person-delete-fill";
import enableFill from "@iconify/icons-eva/person-add-fill";
import viewFill from "@iconify/icons-eva/eye-fill";
import { ActionsMenu } from "../Actions/ActionsMenu";
import { useMutate } from "src/hooks/useFetch.hook";
import { getCustomerById } from "src/react-query/endPoints";
import { useCallback, useMemo } from "react";
import { FEATURE } from "src/util/enums";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/react-query/queryKeys";

export const CustomerActionMenu = ({
  id,
  row: { active, balanceAmount, ocpp_tag_pk, email, phone, domain },
}) => {
  const queryClient = useQueryClient();
  const isReadOnly = useAccess(FEATURE.CUSTOMERS, "R");
  const hasEditAccess = useAccess(FEATURE.CUSTOMERS, "U");
  const hasDeleteAccess = useAccess(FEATURE.CUSTOMERS, "D");
  const { mutate } = useMutate("customer");

  const onDisable = useCallback(() => {
    mutate(
      { url: getCustomerById(id), method: "PATCH", body: { status: "N" } },
      {
        onSuccess: () =>
          queryClient.invalidateQueries(queryKeys["customers"]()),
      }
    );
  }, [id, mutate, queryClient]);

  const onEnable = useCallback(() => {
    mutate(
      { url: getCustomerById(id), method: "PATCH", body: { status: "Y" } },
      {
        onSuccess: () =>
          queryClient.invalidateQueries(queryKeys["customers"]()),
      }
    );
  }, [id, mutate, queryClient]);

  const actionList = useMemo(
    () => [
      {
        id: "view",
        title: "View",
        icon: viewFill,
        routerLink: `${id}`,
        hidden: !isReadOnly,
      },
      {
        id: "edit",
        title: "Edit",
        icon: editFill,
        routerLink: `${ocpp_tag_pk}?amount=${balanceAmount}`,
        hidden: !hasEditAccess,
      },
      {
        id: "wallet_history",
        title: "Wallet History",
        icon: listFill,
        routerLink: `wallet/${id}`,
        hidden: !hasEditAccess,
      },
      {
        id: "customer_transaction_list",
        title: "Transactions",
        icon: listFill,
        routerLink: `/transactions?id=${id}&email=${email}&phone=${encodeURIComponent(
          phone
        )}&domain=${domain}&ocpp_tag_pk=${ocpp_tag_pk}`,
        hidden: !hasEditAccess,
      },
      {
        id: "disable",
        title: "Disable",
        icon: disableFill,
        iconcolor: "red",
        action: onDisable,
        hidden: !hasDeleteAccess || active === "N",
      },
      {
        id: "enable",
        title: "Enable",
        icon: enableFill,
        iconcolor: "#48C13E",
        action: onEnable,
        hidden: !hasDeleteAccess || active === "Y",
      },
    ],
    [
      id,
      isReadOnly,
      hasEditAccess,
      hasDeleteAccess,
      onDisable,
      onEnable,
      active,
    ]
  );

  return <ActionsMenu menuList={actionList} />;
};
