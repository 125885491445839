import { Box, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

import { useRef, useState } from "react";
import Add from "@iconify/icons-eva/plus-fill";
import DeleteIcon from "@iconify/icons-eva/trash-fill";
export const UploadImage = ({
  formValues,
  setFieldValue,
  iconKey,
  iconTitle,
}) => {
  // Ref object to reference the input element
  const inputFile = useRef(null);

  const [backgroundImage, setBackgroundImage] = useState(
    formValues.provider ? formValues.provider[iconKey] : null
  );

  const removeImage = () => {
    setBackgroundImage(null);
    handleReset();
  };
  const handleReset = () => {
    if (inputFile.current) inputFile.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBackgroundImage(reader.result);
        setFieldValue(`provider.${iconKey}`, reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: "8px", alignItems: "center" }}>
        <Typography>{iconTitle}</Typography>
        {backgroundImage && (
          <span style={{ marginLeft: "6em" }}>
            <Icon
              icon={DeleteIcon}
              color="red"
              width="15px"
              height="15px"
              onClick={removeImage}
            />
          </span>
        )}
      </Box>

      <Box
        sx={{
          border: "2px dashed",
          borderColor: "primary.main",
          borderRadius: 2,
          height: "200px",
          width: "200px",
          backgroundImage: `url(${backgroundImage})`, // Set background image
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundSize: "cover", // Ensure image covers the entire Box
          backgroundPosition: "center", // Center the image
        }}
      >
        <input
          onChange={handleFileChange} // Handle file selection
          ref={inputFile}
          style={{ display: "none" }}
          type="file"
          id={`file-upload-${iconKey}`}
        />
        {!backgroundImage && (
          <label htmlFor={`file-upload-${iconKey}`}>
            <Icon icon={Add} width="50px" height="50px" />
          </label>
        )}
      </Box>
    </>
  );
};
