// custom components, services and constants
import Page from "src/components/Page";
import UserProfile from "src/components/UserProfile";
import { BackButton } from "src/common/components/BackButton";

function Profile() {
  return (
  <Page>
    <BackButton
      text="My Profile"
      path="/dashboard"
    />
     <UserProfile />
  </Page>
  )
}

export default Profile;