import { memo, useState, useMemo, useEffect } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

//custom components
import Scrollbar from '../Scrollbar';
import DatatableHeader from './DatatableHeader';
import DatatableToolbar from './DatatableToolbar';
import DatatableBody from './DatatableBody';

const dataTableInitialState = {
    page: 0,
    order: 'asc',
    selected: [],
    orderBy: '',
    rowsPerPage: 5,
    filterText: '',
}

function DataTable({ 
    toolbar, cellHeaders, data, selectedColumnName, primaryKey, onRowEdit,
    renderCell, orderByColumn, dataRenderSchema, actions, onRowDelete }) {
    const [dataTableState, setDataTableState] = useState(dataTableInitialState);

    const appliedFilterData = useMemo(() => {
        const { order, orderBy, filterText } = dataTableState;
        return applySortFilter(data, getComparator(order, orderBy), filterText, selectedColumnName);
    }, [dataTableState.filterText, dataTableState.orderBy, dataTableState.order, data]);

    const emptyRows = useMemo(() => {
        const { page, rowsPerPage } = dataTableState;
        return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0
    }, [dataTableState.rowsPerPage, data]);

    const isDataNotFound = useMemo(() => appliedFilterData.length === 0, [appliedFilterData]);
    // Datatable methods
    const handleFilter = (e) => {
        setDataTableState((state) => ({
            ...state,
            filterText: e.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const { orderBy, order } = dataTableState;
        const isAsc = orderBy === property && order === 'asc';
        setDataTableState((state) => ({
            ...state,
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        }))
    };

    const handleSelectAllClick = (event) => {
        let _selected = [];
        if (event.target.checked) {
            const { rowsPerPage } = dataTableState;
            _selected = appliedFilterData.map((n) => n[selectedColumnName]).slice(0, rowsPerPage)
        }
        setDataTableState((state) => ({
            ...state,
            selected: _selected
        }))
    };

    const onRowChecked = (event, name) => {
        const { selected } = dataTableState;
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          );
        }
        setDataTableState((state) => ({
            ...state,
            selected: newSelected
        }));
    };

    const onPageChanged = (e, page) => {
        setDataTableState((state) => ({
            ...state,
            page
        }))
    }

    const onRowPerPageChanged = (e) => {
        console.log(e.target.value)
        setDataTableState((state) => ({
            ...state,
            page: 0,
            rowsPerPage: Number(e.target.value)
        }))
    }


    useEffect(() => {
        setDataTableState((state) => ({
            ...state,
            orderBy: orderByColumn
        }))
    }, [orderByColumn])

    return (
        <Card className='datatable'>
            {!isEmpty(toolbar) && 
            <Box className="datatable__toolbar">
                <DatatableToolbar 
                    useToolbar={toolbar} 
                    filterText={dataTableState.filterText} 
                    totalSelected={dataTableState.selected.length}
                    onFilter={handleFilter} 
                />
            </Box>}
            <Box className="datatable__container">
                <Scrollbar>
                   <TableContainer>
                       <Table>
                            <DatatableHeader 
                                order={dataTableState.order}
                                orderBy={dataTableState.orderBy}
                                cellHeader={cellHeaders}
                                rowCount={appliedFilterData.slice(0, dataTableState.rowsPerPage).length}
                                numSelected={dataTableState.selected.length}
                                onRequestSort={handleRequestSort}
                                onAllSelect={handleSelectAllClick}
                                actions={actions}
                            />
                            <DatatableBody 
                                bodyData={appliedFilterData}
                                page={dataTableState.page}
                                rowsPerPage={dataTableState.rowsPerPage}
                                selectedData={dataTableState.selected}
                                onRowSelected={onRowChecked}
                                selectedColumnName={selectedColumnName}
                                emptyRows={emptyRows}
                                isDataNotFound={isDataNotFound}
                                filterText={dataTableState.filterText}
                                renderCell={renderCell}
                                columnSchema={dataRenderSchema}
                                actions={actions}
                                onRowDelete={onRowDelete}  
                                onRowEdit={onRowEdit}
                                primaryKey={primaryKey}
                            />
                       </Table>
                   </TableContainer>
                </Scrollbar>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={dataTableState.rowsPerPage}
                    page={dataTableState.page}
                    onPageChange={onPageChanged}
                    onRowsPerPageChange={onRowPerPageChanged}
                />
            </Box>
        </Card>
    )
}

function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
function applySortFilter(array, comparator, query, prop) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (f) => f[prop].toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

DataTable.defaultProps = {
    toolbar: '',
    data: [],
    orderByColumn: '',
    selectedColumnName: 'id',
    dataRenderSchema: [],
    actions: null,
    columnSchema: [],
    actions: false,
    primaryKey: '',
    renderCell: (col) => col,
    onRowDelete: () => {},
    onRowEdit: () => {}
}

export default memo(DataTable);