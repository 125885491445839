import { TabList } from "@mui/lab";
import { Tab, styled } from "@mui/material";

export const RootTabStyle = styled(TabList)(({ theme,length }) => ({
    width:'98%',
    borderTopLeftRadius:theme.spacing(1),
    borderTopRightRadius:theme.spacing(1),
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      width: `${100/length}%`, // Divide the width equally into 5 tabs
    },
    // padding:theme.spacing(1),
    // borderRadius:theme.spacing(1),
    // boxShadow: '0px 3px 15px rgba(34, 35, 58, 0.5)',
  }));
  
  export const RootTabItemStyles = styled(Tab)(({ theme }) => ({
    color:'black',
    background:"#f1f1f1",
    borderTopLeftRadius:theme.spacing(1),
    borderTopRightRadius:theme.spacing(1),
    fontWeight:600,
    paddingLeft:theme.spacing(1),
    paddingRight:theme.spacing(1),
    fontSize:'0.9rem',
    letterSpacing: 0.5,
    marginRight:theme.spacing(0.2),
    marginLeft:theme.spacing(0.2),
    maxWidth:"none",
    '&.Mui-selected':{
      color:'#ffffff',
      background:theme.palette.primary.main,
      transition:'background-color 1s ease-in-out,color 1s ease-in-out'
    }
  }));
  