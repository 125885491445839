import { Box, IconButton, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import arrowBack from "@iconify/icons-eva/arrow-ios-back-outline";
import { Link as RouterLink } from "react-router-dom";

export const BackButton = ({ text, path, action }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 4,
      }}
    >
      <IconButton
        {...(path && { to: path, component: RouterLink })}
        {...(action && { onClick:()=>action() })}
        aria-label="back"
        color="primary"
        sx={{
          bgcolor: 'primary.main',
          borderRadius: "4px",
          height: "30px",
          mr: 2,
          color: "#ffffff",
          "&:hover": { opacity: 1, backgroundColor: 'primary.dark' },
        }}
      >
        <Icon icon={arrowBack} width={24} height={24} />
      </IconButton>
      {text && (
        <Typography variant="body" sx={{ fontWeight: 500, fontSize: "18px" }}>
          {text}
        </Typography>
      )}
    </Box>
  );
};
