import downloadFill from "@iconify/icons-eva/download-fill";
import viewFill from "@iconify/icons-eva/eye-fill";
import { useMemo } from "react";
import { ActionsMenu } from "../Actions/ActionsMenu";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { PayoutPdf } from "./PayoutPdf";

export const TeamPayoutActionMenu = ({ id, row }) => {
  const navigate = useNavigate();
  const DownloadButton = ({ row }) => {
    const downloadPdf = async () => {
      const fileName = "payout.pdf";

      const blob = await pdf(<PayoutPdf row={row} />).toBlob();
      saveAs(blob, fileName);
    };

    return downloadPdf;
  };
  const actionList = useMemo(
    () => [
      {
        id: "download",
        title: "Download Payout",
        icon: downloadFill,
        action: DownloadButton({ id, row }),
      },
      {
        id: "transactions",
        title: "Transactions",
        icon: viewFill,
        action: () => navigate(`/transactions?payoutId=${row.payoutId}`),
      },
    ],
    [id, row, navigate]
  );

  return <ActionsMenu menuList={actionList} />;
};
