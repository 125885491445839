export const  ServiceRequestFormSelectItems=[
    {
        label:"Device not charging",
        value:"Device not charging"
    },
    {
        label:"Dashboard",
        value:"Dashboard"
    },
    {
        label:"Charger issues",
        value:"Charger issues"
    },
    {
        label:"CMS user",
        value:"CMS user"
    },
    {
        label:"EV driver",
        value:"EV driver"
    },
    {
        label:"Price Group",
        value:"Price Group"
    },
    {
        label:"Refund",
        value:"Refund"
    },
    {
        label:"Invoice",
        value:"Invoice"
    },
    {
        label:"Reporting",
        value:"Reporting"
    },
    {
        label:"Others",
        value:"Others"
    },
]