import React from 'react'
import { useMatch } from 'react-router-dom'
import { BackButton } from 'src/common/components/BackButton'
import Page from 'src/components/Page'
import ChargingProfileForm from './ChargingProfileForm'

const ChargingProfileFormView = () => {
  const match = useMatch("/chargingprofile/new")
  const backButtonText = match?"Add Charging Profile Details":"Edit Charging Profile"
  return (
    <Page >
    <BackButton path="/chargingprofile"
      text={backButtonText}
    />
    <ChargingProfileForm currentPage={match?"add":"edit"}/>
    </Page>
  )
}

export default ChargingProfileFormView
