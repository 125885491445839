import React from 'react'
import { useMatch } from 'react-router-dom'
import { BackButton } from 'src/common/components/BackButton'
import Page from 'src/components/Page'
import ServiceRequestForm from './ServiceRequestForm'

const ServiceRequestFormView = () => {

    const match = useMatch('/servicerequest/new')

    return (
        <Page >
            <BackButton
                path="/servicerequest"
                text={
                    match ? "Add New Request" : "Edit Request"
                }
            />
            <ServiceRequestForm />
        </Page>
    )
}

export default ServiceRequestFormView