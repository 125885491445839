import Page from "../Page";
import { useAccess } from "src/hooks/useAccess.hook";
import { Card, Stack } from "@mui/material";
import { BaseTable } from "../BaseTable/BaseTable";
import { priceGroupListColumns } from "./PriceGroup.constants";
import { useList } from "src/hooks/useFetch.hook";
import { FEATURE } from "src/util/enums";
import { useNavigate } from "react-router-dom";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { useState, useEffect } from "react";

export const PriceGroupList = () => {
  const navigate = useNavigate()
  const hasCreateAccess = useAccess(FEATURE.PRICEGROUP, "C");
  const isReadOnly = useAccess(FEATURE.PRICEGROUP, "R");
  const { data: priceGroups, isFetching } = useList("priceGroups");
  const [tableData, setTableData] = useState();
  const rowOrCellClickRedirect = ({ id }) => {
    if(isReadOnly) return;
    navigate(`/pricegroup/${id}`);
  };

  useEffect(() => {
    if (!isFetching){
      let data = [...priceGroups];
      setTableData(data);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = priceGroups;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "name",
            "description",
            "priceGroupType",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "starttime"
                ? yearFirstDateTimeFormat(value).toString()
                : value ? value.toString().toLowerCase() : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...priceGroups]);
  };

  return (
    <Page
     
      {...(hasCreateAccess && { path: `/pricegroup/new` })}
      actionButtonText="Add Price Group"
    >
       <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
      </Stack>
      <Card sx={{ p: 2 }}>
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
        <BaseTable
          rows={tableData ?? []}
          columns={priceGroupListColumns(isReadOnly)}
          loading={isFetching}
          getRowId={(row) => row.priceGroupId}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          pageSize={10}
          isReadOnly={isReadOnly}
          showExportCsvBtn={true}
          csvMorphemes="price-group"
          csvColumns = {priceGroupListColumns(isReadOnly)}
        />
      </Card>
    </Page>
  );
};
