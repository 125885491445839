import { useEffect } from "react";
//formik
import { Form, FormikProvider, useFormik } from "formik";
// mui components
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { LoadingButton } from "@mui/lab";
import { queryKeys } from "src/react-query/queryKeys";

// custom components, services and constants
import { BaseTable } from "src/components/BaseTable/BaseTable";
import { userFormValidationSchema } from "src/util/validationSchemas";
import { Loader } from "src/components/Loader";
import Countries from "src/data/countries.json";
import { getUserProfile } from "src/react-query/endPoints";
import enums from "src/util/enums";
import {
  getUserProfileDefaultValues,
  roleColumns,
} from "./UserProfile.constant";
//actions
import { alertSlice } from "src/store/alert/alert.slice";

// hooks
import { useGetDetail, useMutate } from "src/hooks/useFetch.hook";
import { useAccess } from "src/hooks/useAccess.hook";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

function UserProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isReadOnly } = useAccess("RXX");
  const { mutateAsync, isLoading } = useMutate("user");
  const { data, isFetching } = useGetDetail("profile", { id: 0 });

  const onSubmit = async (values) => {
    const {
      actions: { setAlert },
    } = alertSlice;
    const {
      id,
      zipCode,
      street,
      country,
      houseNumber,
      username,
      email,
      phone,
      lastName,
      firstName,
      city,
      language,
    } = values;
    const profileReqBody = {
      username,
      email,
      phone,
      firstName,
      lastName,
      language,
      id,
      address: {
        zipCode,
        street,
        country,
        houseNumber,
        city,
        addressId: null,
      },
    };
    await mutateAsync(
      {
        url: getUserProfile(),
        method: "PUT",
        body: profileReqBody,
      },
      {
        onSuccess: () => {
          dispatch(
            setAlert({
              open: true,
              message: "Profile Updated Successfully",
              severity: enums.SEVERITY.SUCCESS,
            })
          );
          queryClient.invalidateQueries(queryKeys.user());
        },
        onError: (error) => {
          dispatch(
            setAlert({
              open: true,
              message: error?.response?.data?.message ?? error?.message,
              severity: enums.SEVERITY.ERROR,
            })
          );
        },
      }
    );
  };

  const formik = useFormik({
    initialValues: getUserProfileDefaultValues,
    validationSchema: userFormValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  useEffect(() => {
    if (!isFetching && data) {
      const profileData = { ...data, ...data.address };
      delete profileData.company;
      delete profileData.role;
      for (let [k, v] of Object.entries(profileData)) {
        "role" !== k && setFieldValue(k, v);
      }
    }
  }, [data, isFetching]);

  const rowOrCellClickRedirect = ({ id }) => {
    navigate(`/team/${id}`);
  };
  if (isFetching) {
    return <Loader />;
  }

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit} autoComplete="off" id="profile" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Typography variant="subtitle1" className="mb-2">
              User Detail
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <TextField
                disabled
                label="Username"
                size="small"
                {...getFieldProps("username")}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Typography variant="subtitle1" className="mb-2">
              User Info
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="First Name"
                size="small"
                {...getFieldProps("firstName")}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="Last Name"
                size="small"
                {...getFieldProps("lastName")}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="Email"
                size="small"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="Phone"
                size="small"
                {...getFieldProps("phone")}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-5">
          <Grid item xs={12} lg={12}>
            <Typography variant="subtitle1" className="mb-2 text-uppercase">
              User Address
            </Typography>
            <Divider className="text-muted" />
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="Street"
                size="small"
                disabled={isReadOnly}
                {...getFieldProps("street")}
                error={Boolean(touched.street && errors.street)}
                helperText={touched.street && errors.street}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="House number"
                size="small"
                disabled={isReadOnly}
                {...getFieldProps("houseNumber")}
                error={Boolean(touched.houseNumber && errors.houseNumber)}
                helperText={touched.houseNumber && errors.houseNumber}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="Zip code"
                size="small"
                disabled={isReadOnly}
                {...getFieldProps("zipcode")}
                error={Boolean(touched.zipCode && errors.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth size="small">
              <TextField
                label="City"
                size="small"
                disabled={isReadOnly}
                {...getFieldProps("city")}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl
              fullWidth
              size="small"
              error={Boolean(touched.country && errors.country)}
            >
              <InputLabel id="country">Country</InputLabel>
              <Select
                labelId="country"
                id="country"
                disabled={isReadOnly}
                label="Country"
                {...getFieldProps("country")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Typography variant="subtitle1" className="mb-2">
              User Roles
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} lg={12}>
            <BaseTable
              rows={data?.role ?? []}
              columns={roleColumns}
              loading={isFetching}
              getRowId={(row) => row.teamId}
              rowOrCellClickRedirect={rowOrCellClickRedirect}
            />
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid item xs={6} lg={6}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              disabled={isReadOnly}
              loading={isLoading}
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default UserProfile;
