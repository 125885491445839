import { Box, Divider, Grid, Stack,Button, Modal, Typography } from '@mui/material'
import React from 'react'
import { BaseTable } from 'src/components/BaseTable/BaseTable'
import { GridLabel } from 'src/components/Transactions/TransactionView'
import { statusColumns } from 'src/store/remoteOpsStatus/remoteOpsStatus.constants'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflow: "auto",
  height: "450px",
};
const RemoteOpsStatusModal = ({open,onClose,operation,responseRow}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
    <Box sx={style} >
      <Typography variant='h4'>Task Details</Typography>
    <Stack spacing={2}>
     <Grid container>
     <GridLabel
      label="Operation Name"
      value={`ocpp1.6 / ${operation}`}
      />
      <GridLabel
      label="Origin"
      value="INTERNAL (chargemiles)"
      />
       <GridLabel
      label="Status"
      value={responseRow[0].error!=null?"Error":"Success"}
      color={responseRow[0].error!=null?"red":"green"}
      />
      {/* <GridLabel
      label="Start timestamp"
      value="2023-04-14T15:43:07.423Z"
      />
      <GridLabel
      label="End timestamp"
      value="2023-04-14T15:43:07.423Z"
      />
      <GridLabel
      label="# of charge point requests"
      value="1"
      />
      <GridLabel
      label="# of responses"
      value="0"
      />
      <GridLabel
      label="# of errors"
      value="1"
      /> */}
     </Grid>
      <Stack spacing={2}>
      <Divider textAlign="left"><Typography variant='h6'>Task Result</Typography></Divider>
        <BaseTable
         rows={responseRow ?? []}
         columns={statusColumns}
         loading={false}
         getRowId={(row) => row.id}
         sx={{p:0.5,'& .MuiDataGrid-colCellTitle': { textAlign: 'center' }}}
        />
      </Stack>
    <Button sx={{alignSelf:"end"}} onClick={onClose} variant="contained">Close</Button>
    </Stack>
    </Box>
    </Modal>
  )
}

export default RemoteOpsStatusModal