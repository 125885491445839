import { Card, Stack, TextField, Button, Typography, Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, Chip, Box } from "@mui/material";
import Page from "../Page";
import { useState, useEffect } from "react";
import { useList } from "src/hooks/useFetch.hook";
import axios from "axios";
import { sendMessageUrl } from "src/react-query/endPoints";
import Token from "../../util/Token";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";
import enums from "src/util/enums";

export default function SendMessages() {
  const dispatch = useDispatch();
  const { data: customers, isFetching } = useList("cmCustomers");
  const [message, setMessage] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isFetching && customers) {
      setTableData(customers);
      setFilteredCustomers(customers);
    }
  }, [isFetching, customers]);

  const domainOptions = Array.from(new Set(customers?.map((customer) => customer.domain))).filter(Boolean);

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedDomains(selected);

    if (selected.length > 0) {
      setFilteredCustomers(
        tableData.filter((customer) => selected.includes(customer.domain))
      );
    } else {
      setFilteredCustomers(tableData);
    }
  };

  const BATCH_SIZE = 100;

  const sendBatchNotifications = async (batch) => {
    const payload = {
      message,
      customers: batch.map((customer) => ({
        deviceId: customer.deviceId,
        domain: customer.domain,
        firstName: customer.firstName,
      })),
    };

    await axios.post(sendMessageUrl(), payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token.get()}`,
      },
    });
  };

  const handleSendNotifications = async () => {
    setLoading(true);

    try {
      const batches = [];
      for (let i = 0; i < filteredCustomers.length; i += BATCH_SIZE) {
        batches.push(filteredCustomers.slice(i, i + BATCH_SIZE));
      }

      for (const batch of batches) {
        await sendBatchNotifications(batch);
      }

      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Notifications sent successfully.",
          severity: enums.SEVERITY.SUCCESS,
        })
      );
      setMessage("");
    } catch (error) {
      console.error("Error sending notifications:", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Failed to send notifications.",
          severity: enums.SEVERITY.ERROR,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title="Send Messages">
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Typography variant="h4">Send Push Notifications</Typography>
      </Stack>

      <Card sx={{ p: 2, mt: 2 }}>
        <FormControl fullWidth variant="outlined" sx={{ mt: 2, mb: 2 }}>
          <InputLabel variant="outlined" sx={{ background: '#fff', px: 0.5 }}>Filter by Domain</InputLabel>
          <Select
            multiple
            value={selectedDomains}
            onChange={handleFilterChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {domainOptions.map((domain) => (
              <MenuItem key={domain} value={domain}>
                <Checkbox checked={selectedDomains.includes(domain)} />
                <ListItemText primary={domain} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Message"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ mt: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSendNotifications}
          disabled={loading || !message || !filteredCustomers.length}
          sx={{ mt: 2 }}
        >
          {loading ? "Sending..." : "Send Notifications"}
        </Button>
      </Card>
    </Page>
  );
}
