import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import { useDomainConfig } from "src/hooks/useDomainConfig";
// import logo from "../image/logo.png";
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const domainConfig = useDomainConfig();
  return (
    <Box
      component="img"
      src={domainConfig.logoUrl}
      sx={{ width: "70%", margin: "auto" }}
    />
  );
}
