import * as Yup from "yup";
import { timeDiffInHours } from "src/utils/utility";
import { is } from "./is";

export const chargerFormValidationSchema = Yup.object().shape({
  chargepoint_id: Yup.string()
    .max(60, "Must be 20 characters or less")
    .required("Chargerbox Id is required"),
  chargepoint_name: Yup.string()
    .max(60, "Must be 50 characters or less")
    .required("Chargerbox name is required"),
  street: Yup.string().max(1000, "Must be 1000 characters or less"),
  houseNumber: Yup.string().max(100, "Must be 100 characters or less"),
  zipCode: Yup.string(),
  registrationStatus: Yup.string().required(
    "Please select registration status"
  ),
  city: Yup.string().max(60, "Must be 60 characters or less"),
  country: Yup.string(),
  state: Yup.string().max(60, "Must be 60 characters or less").nullable(!0),
  noOfConnectors: Yup.number().typeError("Only digits is allowed"),
  chargerVisibility: Yup.boolean(),
  employeeOrResident: Yup.boolean(),
  payWithWallet: Yup.boolean(),
  payWithCard: Yup.boolean(),
  tolerance_watt: Yup.number().required(
    "Please define a tolerance level (in watts)"
  ),
  meter_interval_sec: Yup.number().required(
    "Please define a meter interval (in seconds)"
  ),
});

export const transactionStatusValidationSchema = Yup.object().shape({
  notes: Yup.string().max(600, "Must be 600 characters or less"),
  status: Yup.string(),
});

export const payoutAccountValidationSchema = Yup.object().shape({
  // business_name: Yup.string()
  //   .min(4, "Must be atleast 4 characters or more")
  //   .max(200, "Must be 200 or less")
  //   .required("Business / Person name can't be left blank"),

  business_type: Yup.string(),

  // beneficiary_name: Yup.string()
  //   .min(4, "Must be atleast 4 characters or more")
  //   .max(255, "Must be 255 or less")
  //   .required("Beneficiary's name can't be left blank"),

  email: Yup.string().email("Invalid email"),
  // .required("email can't be left blank")
  phone: Yup.number().typeError("Only digits are allowed"),
  // .matches(/^[0-9\- ]{8,15}\s*$/, "Invalid phone number")
  // .required("Phone number can't be left blank")
  // account_no: Yup.string()
  //   .min(6, "Bank account number should be between 6 and 17 digits")
  //   .max(17, "Bank account number should be between 6 and 17 digits")
  //   .matches(/^[a-zA-Z0-9]+\s*$/, "Account number is to be alphanumeric")
  //   .required("Bank account's number can't be left blank"),

  // ifsc_code: Yup.string()
  //   .min(11, "Must be of 11 alphanumeric characters")
  //   .matches(/^[a-zA-Z]{4}[a-zA-Z0-9]{7}\s*$/, "Invalid IFSC code")
  //   .required("Bank account's IFSC code can't be left blank"),

  // gst: Yup.string()
  //   .matches(
  //     /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1})\s*$/,
  //     "Invalid GST number"
  //   ),
  account_type: Yup.string(),
  payout_percentage: Yup.number().typeError("Only digits are allowed"),
  tax_percentage: Yup.number().typeError("Only digits are allowed"),
});

export const financeConfigValidationSchema = {
  charger_payment_details: Yup.object().shape({
    monthly_payment: Yup.boolean(),
    dc_charges_monthly: Yup.number().typeError("Only digits are allowed"),
    ac_charges_monthly: Yup.number().typeError("Only digits are allowed"),
    dc_charges_annual: Yup.number().typeError("Only digits are allowed"),
    ac_charges_annual: Yup.number().typeError("Only digits are allowed"),
    tax: Yup.number().typeError("Only digits are allowed"),
    other_fixed_cost: Yup.number().typeError("Only digits are allowed"),
  }),
  transaction_payment_details: Yup.object().shape({
    pg_charges_bearer: Yup.string().oneOf(["CPO", "EV"], "Invalid selection"),
    payment_gateway_percent: Yup.number().typeError("Only digits are allowed"),
    tax: Yup.number().typeError("Only digits are allowed"),
  }),
  payout_details: Yup.object().shape({
    payout_rule: Yup.string(),
    tax_bearer: Yup.string().oneOf(["CPO", "CM"], "Invalid selection"),
    tax: Yup.number().typeError("Only digits are allowed"),
    chargemiles_commission_in_percent: Yup.boolean(),
    chargemiles_commission_percent: Yup.mixed().when(
      "chargemiles_commission_in_percent",
      {
        is: (chargemiles_commission_in_percent) =>
          chargemiles_commission_in_percent,
        then: Yup.number().typeError("Only digits are allowed"),
      }
    ),

    chargemiles_commission_cost: Yup.mixed().when(
      "chargemiles_commission_in_percent",
      {
        is: (chargemiles_commission_in_percent) =>
          !chargemiles_commission_in_percent,
        then: Yup.number().typeError("Only digits are allowed"),
      }
    ),
  }),
};

export const teamFormValidationSchema = Yup.object().shape({
  name: Yup.string().max(20, "Must be 20 characters or less"),
  // .required("Team name is required")
  active: Yup.string().required("status is required"),
  // .required("Account number is required")
  payoutAccounts: Yup.array().of(payoutAccountValidationSchema),
  financeConfig: Yup.object().shape(financeConfigValidationSchema),
  settlement_percentage: Yup.number(),
});

export const companyFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(60, "Must be 20 characters or less")
    .required("Company Name Id is required"),
  // paymentConfirmationNo: Yup.string()
  //   .max(60, "Must be 20 characters or less")
  //   .required("Payment type Id is required"),
  // subscriptionId: Yup.string()
  //   .max(60, "Must be 20 characters or less")
  //   .required("Subscription Id is required"),
  active: Yup.string()
    .max(60, "Must be 20 characters or less")
    .required("Feature Id is required"),
  rootUser: Yup.string()
    .max(60, "Must be 20 characters or less")
    .required("Root user Id is required"),
  emailId: Yup.string()
    .max(60, "Must be 20 characters or less")
    .required("Email Id is required"),
  paymentCurrency: Yup.string().required("Please select a currency"),
  // transactionPercent:Yup.string()
  // .required("Please specify transaction percentage")
  // .test("is-float","Specify valid decimal value of upto 2 places",(val)=>/^\d+(\.\d{1,2})?$/.test(val)),
  // taxPercent:Yup.string()
  // .required("Please specify tax percentage")
  // .test("is-float","Specify valid decimal value of upto 2 places",(val)=>/^\d+(\.\d{1,2})?$/.test(val)),
  // comments: Yup.string()
  //   .max(60, "Must be 20 characters or less")
  //   .required("Comments is required"),
  // address: Yup.object().shape({
  //   street: Yup.string()
  //     .max(20, "Must be 60 characters or less")
  //     .required("Street is required"),
  //   zipCode: Yup.number()
  //     .typeError("Please specify only numbers")
  //     .required("Zipcode is required"),
  //   houseNumber: Yup.number()
  //     .typeError("Please specify only numbers")
  //     .required("HouseNUmber is required"),
  //   city: Yup.string()
  //     .max(60, "Must be 60 characters or less")
  //     .required("City is required"),
  //   country: Yup.string().required("Please select country"),
  // }),
});

export const tagFormValidationSchema = Yup.object().shape({
  tag: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("ID Tag is required"),
  balance_amount: Yup.number()
    .min(0)
    .typeError("Balance amount must be number")
    .nullable(),
  expirydate: Yup.date("yyyy-MM-dd")
    .typeError("Please enter valid Date")
    .min(new Date(), "Please provide a valid future date."),
});

export const priceGroupValidationSchema = Yup.object().shape(
  {
    name: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Price Group name is required"),
    priceDetails: Yup.array().of(
      Yup.object().shape({
        priceperMin: Yup.number().when("priceType", {
          is: "min",
          then: Yup.number().required("Price Per Min is required"),
          otherwise: Yup.number().default(1),
        }),
        priceperKW: Yup.number().when("priceType", {
          is: "kwh",
          then: Yup.number().required("Price Per kwh is required"),
          otherwise: Yup.number().default(1),
        }),
        // fixedFeeStarting: Yup.number().positive("Must be greater than 0").required("Fixed Fee Starting is required"),
        // fixedFeeCharging: Yup.number().positive("Must be greater than 0").required("Fixed Fee Charging is required"),
        // taxPercent: Yup.number().positive("Must be greater than 0").required("Tax Percent is required"),
        startTime: Yup.string().required("Start Time is required"),
        endTime: Yup.string()
          .required("End Time is required")
          .test(
            "endTime",
            "End Time must be greater than Start Time",
            function () {
              return (
                timeDiffInHours(this.parent.startTime, this.parent.endTime) > 0
              );
            }
          ),
      })
    ),
  },
  [["startTime", "endTime"]]
);

export const userFormValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
});

export const loginFormValidationSchema = Yup.object().shape({
  domainName: Yup.string(),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

export const resetPasswordValidationSchmea = Yup.object().shape({
  username: Yup.string().required("Username is required"),
});

export const verficationValidationSchema = Yup.object().shape({
  otp: Yup.number()
    .typeError("Numbers is allowed")
    .required("Please input otp"),
  old_password: Yup.string().required("Please input old password"),
  new_password: Yup.string()
    .required("Please input new password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
      "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirm_password: Yup.string()
    .required("Please input confirm password")
    .oneOf([Yup.ref("new_password"), null], `Passwords doesn't match`),
});

export const roleFormValidationSchema = Yup.object().shape({
  roleName: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Role name is required"),
});

export const customerValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email"),
  phone: Yup.string().matches(/^[0-9\-+ ]{8,14}$/, "Invalid phone number"),
  // ocppTagId: Yup.string().required("OCPP Tag is required"),
});

export const serviceRequestFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid mail address")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9\- ]{8,14}$/, "Invalid phone number")
    .required("Phone number is required"),
  issueType: Yup.string().required("Choose the issue type"),
  description: Yup.string()
    .max(100, "Must be 100 characters or less")
    .required("Give some details"),
  // attachment:Yup.mixed().test("fileSize","The file is too large",(file)=>{
  //    return file && file.size<=10485760
  // })
});

export const walletFormValidationSchema = () =>
  Yup.object().shape({
    note: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Note is required"),
    amount: Yup.number()
      .max(1000, "Amount Should be between 0 and 1000")
      .min(0, "Amount should not be less than 0"),
    firstName: Yup.string()
      .max(100, "Must be 100 characters or less"),
    lastName: Yup.string()
      .max(100, "Must be 100 characters or less"),
    email: Yup.string().email("Invalid email"),
    phone: Yup.string()
      .matches(/^\+?[1-9]\d{1,3}[0-9\- ]{8,14}$/, "Invalid phone number"), // Updated regex
  });
  