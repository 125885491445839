import React, { useEffect, useState } from "react";
import { Card, Stack } from "@mui/material";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import { useServiceRequests } from "../serviceRequest.hook";
import { serviceRequestListColumns } from "./ServiceRequestList.constants";
import {  useNavigate } from "react-router-dom";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import AddNewBtn from "src/components/AddNewBtn";

const ServiceRequestList = () => {
  const navigate = useNavigate();
  // const hasCreateAccess = useAccess(FEATURE.SERVICEREQUEST, "C");
  const isReadOnly = useAccess(FEATURE.SERVICEREQUEST, "R");
  const hasCreateAccess = true;
  const { data, isFetching } = useServiceRequests();
  const [tableData, setTableData] = useState([]);
  const rowOrCellClickRedirect = ({ id }) => {
    if(isReadOnly) return;
    navigate(`edit/${id}`);
  };

  useEffect(() =>{console.log(isFetching)},[isFetching])


  useEffect(() => {
    if (!isFetching){
      let arr = [...data];
      console.log(arr)
      setTableData(arr);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = data;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "description",
            "email",
            "issueType",
            "phone",
            "status",
            "ticket_id",
            "createAt",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "createAt"
                ? yearFirstDateTimeFormat(value).toString()
                : value ?  value.toString().toLowerCase() : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...data]);
  };

  return (
      <>
      <Stack
        direction="row"
        spacing={2}
        sx={{placeContent: "end"}}
      >
        {hasCreateAccess && (
          <AddNewBtn label="Add Service Request" to="/servicerequest/new" />
        )}
      </Stack>
      <Card sx={{ p: 2 }}>
      <CommonSearchbar
          placeholder="Search"
          searchFunc={handleSearching}
        />
      <BaseTable
        rows={tableData}
        loading={isFetching}
        columns={serviceRequestListColumns(isReadOnly)}
        getRowId={(row) => row.ticket_id}
        pageSize={10}
        rowOrCellClickRedirect={rowOrCellClickRedirect}
        isReadOnly={isReadOnly}
        showExportCsvBtn={true}
        csvMorphemes="service_request"
        csvColumns = {serviceRequestListColumns(isReadOnly)}
      />
    </Card>
    </>
  );
};

export default ServiceRequestList;
