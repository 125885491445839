import { LoadingButton } from "@mui/lab";
import {
  Card,
  FormControl,
  Grid,
  Stack,
  TextField,
  Tabs,
  Tab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useMutate, useFetch } from "src/hooks/useFetch.hook";
import enums, { FEATURE } from "src/util/enums";
import {
  customerWalletUrl,
  customerDetailUrl,
  customerRfidUrl,
  deleteCustomerRfidUrl,
  addCustomerRfidUrl,
  customerUpiIdUrl,
  addUpiIdUrl,
  deleteUpiIdUrl,
  sendMessageUrl,
} from "src/react-query/endPoints";
import { walletFormValidationSchema } from "src/util/validationSchemas";
import Page from "../Page";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default function CMCustomerWalletForm() {
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutate("companyList");
  const { id: customerId } = useParams();
  const [customerData, setCustomerData] = useState();
  const [rfidTags, setRfidTags] = useState([]);
  const [newRfidTag, setNewRfidTag] = useState("");
  const [upiIds, setUpiIds] = useState([]);
  const [newUpiId, setNewUpiId] = useState("");
  const dispatch = useDispatch();

  const { fetchData, isFetching } = useFetch();
  const [tabValue, setTabValue] = useState(0);
  const [messageContent, setMessageContent] = useState("");

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await fetchData({
          url: customerDetailUrl(customerId),
          method: "GET",
        });
        console.log(`response: `, response.data);
        if (response && response.data) {
          setCustomerData(response.data);
        }
      } catch (error) {
        console.error(`Error fetching customer data: ${error}`);
        dispatch(
          alertSlice.actions.setAlert({
            open: true,
            message: "Error fetching customer data",
            severity: enums.SEVERITY.ERROR,
          })
        );
      }
    };

    if (customerId && !customerData) {
      fetchCustomerDetails();
    }
  }, [customerId, fetchData]);

  const fetchRfidTags = async () => {
    if (!customerData) {
      console.error("Customer data not found");
      return;
    }
    try {
      const response = await fetchData({
        url: customerRfidUrl(),
        method: "POST",
        body: { user: customerData },
      });
      setRfidTags(response.data);
    } catch (error) {
      console.error("Error fetching RFID tags: ", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Error fetching RFID tags",
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  };

  const fetchUpiIds = async () => {
    if (!customerData) {
      console.error("Customer data not found");
      return;
    }
    try {
      const response = await fetchData({
        url: customerUpiIdUrl(),
        method: "POST",
        body: { user: customerData },
      });
      setUpiIds(response.data);
    } catch (error) {
      console.error("Error fetching RFID tags: ", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Error fetching UPI IDs",
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  };

  const addRfidTag = async () => {
    try {
      const response = await fetchData({
        url: addCustomerRfidUrl(newRfidTag),
        method: "POST",
        body: { user: customerData, tagId: newRfidTag },
      });
      setNewRfidTag("");
      fetchRfidTags();
    } catch (error) {
      console.error("Error adding RFID tag: ", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: `Error adding RFID tag: ${error.message}`,
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  };

  const removeRfidTag = async (tagId) => {
    try {
      await fetchData({
        url: `${deleteCustomerRfidUrl(tagId)}`,
        method: "DELETE",
        body: { user: customerData },
      });
      fetchRfidTags();
    } catch (error) {
      console.error("Error removing RFID tag: ", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: `Error removing RFID tag: ${error.message}`,
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  };

  const addUpiId = async () => {
    try {
      const response = await fetchData({
        url: addUpiIdUrl(newUpiId),
        method: "POST",
        body: { user: customerData, upi_id: newUpiId },
      });
      setNewUpiId("");
      fetchUpiIds();
    } catch (error) {
      console.error("Error adding RFID tag: ", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: `Error adding UPI ID: ${error.message}`,
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  };

  const removeUpiId = async (upiId) => {
    try {
      await fetchData({
        url: `${deleteUpiIdUrl(upiId)}`,
        method: "DELETE",
        body: { user: customerData },
      });
      fetchUpiIds();
    } catch (error) {
      console.error("Error removing RFID tag: ", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: `Error removing UPI ID: ${error.message}`,
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  };

  const onSubmit = async (walletForm, { setSubmitting }) => {
    const mutateObject = {
      url: customerWalletUrl(customerData?.ocppTagId),
      method: "POST",
    };
    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: { ...walletForm },
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const onSuccess = () => {
    console.log("success");
    navigate("/cm-customer");
  };

  const formik = useFormik({
    initialValues: {
      amount: customerData?.balanceAmount || 0,
      note: "",
      firstName: customerData?.firstName || "",
      lastName: customerData?.lastName || "",
      email: customerData?.email || "",
      phone: customerData?.phone || "",
      ocppTagId: customerData?.ocppTagId || "",
    },
    enableReinitialize: true,
    validationSchema: walletFormValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      fetchRfidTags();
    } else if (newValue === 2) {
      fetchUpiIds();
    }
  };

  const sendMessageToCustomer = async () => {
    if (!messageContent) return;
    const customers = [customerData];
    try {
      await fetchData({
        url: sendMessageUrl(),
        method: "POST",
        body: {
          customers,
          message: messageContent,
        },
      });
      setMessageContent("");
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Message sent successfully!",
          severity: enums.SEVERITY.SUCCESS,
        })
      );
    } catch (error) {
      console.error("Error sending message: ", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: `Error sending message: ${error.message}`,
          severity: enums.SEVERITY.ERROR,
        })
      );
    }
  };

  return (
    <Page>
      <BackButton
        text={`Edit Customer Details: ${customerData?.id_tag}`}
        path="/cm-customer"
      />
      <Card sx={{ p: 2 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="customer wallet tabs"
        >
          <Tab label="General" />
          <Tab label="RFID Tags" />
          <Tab label="UPI IDs" />
          <Tab label="Send Message" />
        </Tabs>

        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            id="company"
            noValidate
            onSubmit={handleSubmit}
          >
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      id="firstName"
                      label="First Name"
                      size="small"
                      {...getFieldProps("firstName")}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      id="lastName"
                      label="Last Name"
                      size="small"
                      {...getFieldProps("lastName")}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      id="email"
                      label="Email"
                      size="small"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      id="phone"
                      label="Phone"
                      size="small"
                      {...getFieldProps("phone")}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      id="outlined-basic"
                      label="Wallet Amount"
                      size="small"
                      type="number"
                      {...getFieldProps("amount")}
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      required
                      id="outlined-basic"
                      label="Note"
                      size="small"
                      {...getFieldProps("note")}
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid item xs={6} lg={6}>
                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting || isLoading || isFetching}
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      component={Link}
                      to="/cm-customer"
                    >
                      Cancel
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </TabPanel>

            {/* RFID Tags Tab */}
            <TabPanel value={tabValue} index={1}>
              <TextField
                label="New RFID Tag"
                value={newRfidTag}
                onChange={(e) => setNewRfidTag(e.target.value)}
                fullWidth
                sx={{ mb: 2, my: 3 }}
              />
              <LoadingButton
                size="large"
                variant="contained"
                onClick={addRfidTag}
                loading={isFetching}
                disabled={!newRfidTag}
                sx={{ mb: 3 }}
              >
                Add RFID Tag
              </LoadingButton>
              <h4>Attached RFID Tags:</h4>
              <List
                sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px", p: 2 }}
              >
                {rfidTags.map((tag) => (
                  <div key={tag.id}>
                    <ListItem
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        mb: 1,
                      }}
                    >
                      <ListItemText primary={tag.tag} />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => removeRfidTag(tag.id)}
                      >
                        <DeleteIcon sx={{ color: "#f44336" }} />
                      </IconButton>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <TextField
                label="New UPI Id"
                value={newUpiId}
                onChange={(e) => setNewUpiId(e.target.value)}
                fullWidth
                sx={{ mb: 2, my: 3 }}
              />
              <LoadingButton
                size="large"
                variant="contained"
                onClick={addUpiId}
                loading={isFetching}
                disabled={!newUpiId}
                sx={{ mb: 3 }}
              >
                Add UPI Id
              </LoadingButton>
              <h4>Attached UPI Ids:</h4>
              <List
                sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px", p: 2 }}
              >
                {upiIds.map((upi) => (
                  <div key={upi.upi_id}>
                    <ListItem
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        mb: 1,
                      }}
                    >
                      <ListItemText primary={upi.upi_id} />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => removeUpiId(upi.upi_id)}
                      >
                        <DeleteIcon sx={{ color: "#f44336" }} />
                      </IconButton>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label="Message"
                  multiline
                  rows={4}
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                  variant="outlined"
                  sx={{ mb: 2, my: 3 }}
                />
              </FormControl>
              <LoadingButton
                size="large"
                variant="contained"
                onClick={sendMessageToCustomer}
                disabled={!messageContent}
              >
                Send Message
              </LoadingButton>
            </TabPanel>
          </Form>
        </FormikProvider>
      </Card>
    </Page>
  );
}
