import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import { alertSlice } from "src/store/alert/alert.slice";
import { FEATURE } from "src/util/enums";
import Page from "../Page";
import TagForm from "./TagForm";

export const Tag = () => {
  const navigate = useNavigate();
  const newTagMatch = useMatch("/tag/new");
  const isReadOnly = useAccess(FEATURE.TAG, "R");
  const notAuthorized = useAccess(FEATURE.TAG, "N");
  const createAccess = useAccess(FEATURE.TAG, "C");
  const dispatch = useDispatch();

  useEffect(() => {
    if ((newTagMatch && !createAccess) || (!newTagMatch && notAuthorized)) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate(sidebarConfig[FEATURE.TAG].path);
    }
  }, [dispatch, newTagMatch, createAccess, navigate, notAuthorized]);
  return (
    <Page >
      <BackButton
        text={
          newTagMatch
            ? "Create new RFID Tag"
            : isReadOnly
            ? "RFID Tag Details"
            : "Edit RFID Tag details"
        }
        path="/tag"
      />
      <TagForm />
    </Page>
  );
};
