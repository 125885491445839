import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { TransactionActionMenu } from "./TransactionActionMenu";
import Tooltip from "@mui/material/Tooltip";

export const transactionsListColumns = (isReadOnly)=>([
  {
    field: "transactionId",
    headerName: "Transaction ID",
    flex: 0.7,
    renderCell: ({ value, id }) => (
      <>
      {isReadOnly?value:<Link underline="none" component={RouterLink} to={`/transactions/${id}`}>
        {value}
      </Link>}
      </>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: "phone",
    headerName: "Phone no.",
    flex: 0.5,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: "chargepoint_id",
    headerName: "ChargeBox ID",
    flex: 0.7,
  },
  {
    field: "connectorId",
    headerName: "Connector ID",
    flex: 0.6,
  },
  {
   field:"payment_type",
   headerName:"Payment Type",
   flex:0.5
  },
  {
    field: "totalAmountPaid",
    headerName: "Amount Paid",
    flex: 0.6,
  },
  {
    field: "totalAmountCharged",
    headerName: "Charging Cost",
    flex: 0.6,
  },
  {
    field: "totalRefund",
    headerName: "Refund / Wallet balance",
    flex: 0.8,
  },
  {
    field: "totalKw",
    headerName: "Total Kw Consumed"
  },
  {
    field: "starttime",
    headerName: "Start Date/Time",
    type: "date",
    flex: 0.7,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "stoptime",
    headerName: "Stop Date/Time",
    type: "date",
    flex: 0.7,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "status",
    headerName: "status",
    flex: 0.8,
  },
  {
    field: "notes",
    headerName: "notes",
    flex: 0.8,
  },
  {
    field: "Actions",
    flex: 0.5,
    sortable: false,
    renderCell: TransactionActionMenu,
  },
]);

export const chartLabelConstants = {
  "Current.Export": "Instantaneous current flow from EV",
  "Current.Import": "Instantaneous current flow to EV",
  "Current.Offered": "Maximum current offered to EV",
  "Energy.Active.Export.Register": "Energy exported by EV (Wh or kWh)",
  "Energy.Active.Import.Register": "Energy imported by EV (Wh or kWh)",
  "Energy.Reactive.Export.Register":
    "Reactive energy exported by EV (varh or kvarh)",
  "Energy.Reactive.Import.Register":
    "Reactive energy imported by EV (varh or kvarh)",
  "Energy.Active.Export.Interval": "Energy exported by EV (Wh or kWh)",
  "Energy.Active.Import.Interval": "Energy imported by EV (Wh or kWh)",
  "Energy.Reactive.Export.Interval":
    "Reactive energy exported by EV. (varh or kvarh)",
  "Energy.Reactive.Import.Interval":
    "Reactive energy imported by EV. (varh or kvarh)",
  Frequency: "Instantaneous reading of powerline frequency",
  "Power.Active.Export": "Instantaneous active power exported by EV. (W or kW)",
  "Power.Active.Import": "Instantaneous active power imported by EV. (W or kW)",
  "Power.Factor": "Instantaneous power factor of total energy flow",
  "Power.Offered": "Maximum power offered to EV",
  "Power.Reactive.Export":
    "Instantaneous reactive power exported by EV. (var or kvar)",
  "Power.Reactive.Import":
    "Instantaneous reactive power imported by EV. (var or kvar)",
  RPM: "Fan speed in RPM",
  Soc: "State of charge of charging vehicle in percentage",
  SoC: "State of charge of charging vehicle in percentage",
  Temperature: "Temperature reading inside Charge Point",
  Voltage: "Instantaneous AC RMS supply voltage",
};

export const chartTypeToShow = [
  "Temperature",
  "Voltage",
  "Current.Import",
  "Power.Active.Import",
  "SoC"
];
