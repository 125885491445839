// material
import { useMatch, useNavigate } from "react-router-dom";
import Page from "../Page";
import CustomerForm from "./CustomerForm";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { alertSlice } from "src/store/alert/alert.slice";

export default function Customer() {
  const newUserMatch = useMatch("/customer/new");
  const navigate = useNavigate();
  const isReadOnly = useAccess(FEATURE.CUSTOMERS, "R");
  const notAuthorized = useAccess(FEATURE.CUSTOMERS, "N");
  const createAccess = useAccess(FEATURE.CUSTOMERS, "C");
  const dispatch = useDispatch();

  useEffect(() => {
    if ((newUserMatch && !createAccess) || (!newUserMatch && notAuthorized)) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate("/customers", { replace: true });
    }
  }, [dispatch, newUserMatch, createAccess, navigate, notAuthorized]);

  return (
    <Page>
      <BackButton
        text={
          newUserMatch
            ? "Create new customer"
            : isReadOnly
            ? "Customer Details"
            : "Edit customer details"
        }
        path="/customer"
      />
      <CustomerForm />
    </Page>
  );
}
