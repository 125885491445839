import {
  differenceInCalendarDays,
  format,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  isWithinInterval,
  subDays,
} from "date-fns";
import { is } from "src/util/is";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date, dateFormat = "dd MMM yyyy HH:mm") {
  const parsedDate = new Date(date);
  return !is.empty(date) && isValid(parsedDate)
    ? format(parsedDate, dateFormat)
    : "";
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function f(date, _format) {
  return format(new Date(date), _format);
}

export function fLocalTime(date) {
  return !is.empty(date) && isValid(new Date(date))
    ? format(new Date(date), "MMMM dd, yyyy, hh:mm:ss")
    : "";
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
export const onlyTime = (date) => {
  return !is.empty(date) && isValid(new Date(date))
    ? format(new Date(date), "hh:mm:ss a")
    : "";
};

export const yearFirstDateTimeFormat = (date) => {
  return !is.empty(date) && isValid(new Date(date))
    ? format(new Date(date), "yyyy-MM-dd hh:mm:ss a")
    : "";
};

export const changeToISODateTimeString = (data) => {
  let date = data;
  if (typeof date === "object") {
    date = date.toISOString();
  } else {
    date = new Date(date).toISOString();
  }
  const withoutUTCZulu = date.replace("Z", "+00:00");
  return withoutUTCZulu;
};

export const remoteOperationsDateFormat = (data) => {
  let date = data;
  if (typeof date === "object") {
    date = date.toISOString();
  } else {
    date = new Date(date).toISOString();
  }
  return !is.empty(date) && isValid(new Date(date))
    ? format(new Date(date), "yyyy-MM-dd HH:mm")
    : "";
};
export const withinTimeBounds = (
  dateTimeToCompare,
  dateTimeToCompareAgainst,
  limitToConsider
) => {
  const upperLimitBound = new Date(dateTimeToCompareAgainst);
  const lowerTimeBound = new Date(
    upperLimitBound.getTime() - limitToConsider * 60000
  );

  const isWithinBounds = isWithinInterval(dateTimeToCompare, {
    start: lowerTimeBound,
    end: upperLimitBound,
  });
  return isWithinBounds;
};

export const giveTimeAndUTCTimeOffset = (
  timeToCalculateAgainst = new Date()
) => {
  const timezoneOffset = format(timeToCalculateAgainst, "xxx"); //OFFSET IN FORMAT '+/- hh:mm'
  const offsetInHrs = timezoneOffset.toString().split(/-|\+/gm)[1]; // ARRAY OF OFFSET STRING ['+||-',"hh:mm"]
  const offsetDirection =
    offsetInHrs.split(":")[0] === "00"
      ? "equal"
      : timezoneOffset[0] === "+"
      ? "ahead"
      : "behind";
  return offsetDirection !== "equal" ? (
    <p style={{ color: "red" }}>
      Your current timezone is {offsetInHrs}hrs {offsetDirection}.
    </p>
  ) : (
    <p style={{ color: "green" }}>
      Your current timezone is in accordance with UTC.
    </p>
  );
};

export const dashboardDateFormatter = (date, dateFormat = "yyyy-MM-dd") => {
  return format(date, dateFormat);
};

export const timeIntervalFormatter = (endDate, intervalValue) => {
  const startDate = subDays(endDate, intervalValue);
  return {
    start: dashboardDateFormatter(startDate),
    end: dashboardDateFormatter(endDate),
  };
};

export const isValidTimeInterval = ({ start, end }) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const today = new Date();
  const isStartBeforeOrEqualToEnd =
    isBefore(startDate, endDate) || isEqual(startDate, endDate);
  const bothNotAfterCurrent =
    isAfter(startDate, today) && isAfter(endDate, today);
  return isStartBeforeOrEqualToEnd && !bothNotAfterCurrent;
};

export const differenceBetween = ({ start, end }) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const difference = differenceInCalendarDays(endDate, startDate);
  return difference;
};

export const calculateBaseDateRange = ({ start, end, type }) => {
  let dateGap = differenceBetween({ start, end });
  let previousDay = subDays(new Date(start), 1);
  const baseDateRange = timeIntervalFormatter(previousDay, dateGap);

  return {
    start,
    end,
    type,
    baseStart: baseDateRange.start,
    baseEnd: baseDateRange.end,
  };
};

export const addTimeToDateString = (
  dateString,
  time = format(new Date(), "HH:mm:ss")
) => {
  const updatedDateString = `${dateString} ${time}`;
  // console.log(updatedDateString);
  return dateString;
};

export const splitDateAndTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; 
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "-" + mm + "-" + yyyy;
};
