import { useState, useRef } from "react";
import { Icon } from "@iconify/react";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import { Link as RouterLink } from "react-router-dom";
//material ui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

export const ActionsMenu = ({ menuList }) => {
  const actionRef = useRef();
  const [openMenu, setMenuOpen] = useState(!1);
  const handleMenu = () => setMenuOpen(!openMenu);

  return (
    <Box className="datatable__actions">
      <IconButton ref={actionRef} onClick={handleMenu}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={openMenu}
        anchorEl={actionRef.current}
        onClose={handleMenu}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuList
          ?.filter((item) => !item.hidden)
          ?.map(({ id, title, icon, disabled, routerLink, action,iconContainer,iconcolor }) => (
            <MenuItem
              key={id}
              {...(routerLink && { component: RouterLink, to: routerLink })}
              disabled={disabled}
              onClick={() => {
                if (action && !action()) {
                  handleMenu();
                }
              }}
              sx={{ color: "text.secondary" }}
            >
              {(iconContainer || icon) && (
                <ListItemIcon>
                 { iconContainer || <Icon icon={icon} width={24} height={24} color={iconcolor} /> }
                </ListItemIcon>
              )}
              <ListItemText
                primary={title}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};
