import { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography, Grid } from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
// import AuthSocial from "../components/authentication/AuthSocial";
import Token from "src/util/Token";
import enums from "src/util/enums";
import Logo from "src/components/Logo";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

//const SectionStyle = styled(Card)(({ theme }) => ({
//   width: "100%",
//   maxWidth: 464,
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   margin: theme.spacing(2, 0, 2, 2),
// }));

// const ContentStyle = styled("div")(({ theme }) => ({
//   maxWidth: 480,
//   margin: "auto",
//   display: "flex",
//   minHeight: "100vh",
//   flexDirection: "column",
//   justifyContent: "center",
//   padding: theme.spacing(12, 0),
// }));

// ----------------------------------------------------------------------

export default function Login() {
  const [error, setError] = useState(null);
  useEffect(() => {
    const redirectedURL = new URLSearchParams(window.location.search);
    const loginStatus = redirectedURL.get("emailVerificationStatus");
    switch (loginStatus) {
      case "alreadyVerified":
        setError("Email is already verified, Please login.");
        break;
      case "false":
        setError("Email verification failed, Please try again or contact us.");
        break;
      default:
        //  Token.get() && (window.location.href = enums.path.DASHBOARD_APP);
        break;
    }
  }, []);

  return (
    <RootStyle >
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Grid item md={6}>
            <MHidden width="mdDown">
              <SectionStyle>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                  <Logo />
                </Typography>
                <img
                  src="/static/illustrations/illustration_login.png"
                  alt="login"
                />
              </SectionStyle>
            </MHidden>
          </Grid> */}
        <Grid item md={12} xs={12} minWidth={"50%"} mt={6}>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
            {/* <Typography sx={{ color: "text.secondary" }}>
                Enter your details below.
              </Typography> */}
          </Stack>
          <LoginForm />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden>
        </Grid>
      </Container>
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get started
        </Link>
       </AuthLayout> */}
    </RootStyle>
  );
}
