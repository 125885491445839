export const domainDetails = {
  chargemiles: {
    palette: {
      primary: {
        lighter: "#C8FACD",
        light: "#5BE584",
        main: "#00AB55",
        dark: "#007B55",
        darker: "#005249",
        contrastText: "#fff",
      },
    },
    logoUrl: "logo/chargemiles.jpeg",
    faviconUrl: "chargemilesFavicon.ico",
    title: "ChargeMiles-CMS",
    mapUrl: "https://map.chargemiles.ca",
    domain:"chargemiles",
    filterColor:
      "invert(20%) sepia(100%) saturate(5000%) hue-rotate(100deg) brightness(95%) contrast(105%);",
  },
  moveonev: {
    domain:"moveonev",
    palette: {
      primary: {
        lighter: "#C8FACD",
        light: "#5BE584",
        main: "#dc3545",
        dark: "#A60020",
        darker: "#005249",
        contrastText: "#fff",
      },
    },
    logoUrl: "logo/moveon.png",
    title: "MoveonEV-CMS",
    mapUrl: "https://map.moveonev.com",
    faviconUrl: "moveonevfavicon.ico",
    filterColor:
      "invert(25%) sepia(89%) saturate(3548%) hue-rotate(341deg) brightness(94%) contrast(82%);",
  },
  amikcharge: {
    domain:"amikcharge",
    palette: {
      primary: {
        lighter: "#C8FACD",
        light: "#5BE584",
        main: "#007fff",
        dark: "#003366",
        darker: "#005249",
        contrastText: "#fff",
      },
    },
    logoUrl: "logo/amikCharge.svg",
    title: "AmikCharge-CMS",
    mapUrl: "https://map.amiktechnologies.com",
    faviconUrl: "amikChargeFavicon.ico",
    filterColor:
      " brightness(0) saturate(100%) invert(33%) sepia(31%) saturate(4652%) hue-rotate(197deg) brightness(101%) contrast(110%)",
  },
  amiktechnologies: {
    domain:"amiktechnologies",
    palette: {
      primary: {
        lighter: "#C8FACD",
        light: "#5BE584",
        main: "#007fff",
        dark: "#003366",
        darker: "#005249",
        contrastText: "#fff",
      },
    },
    logoUrl: "logo/amikCharge.jpg",
    title: "AMIKTechnologies-CMS",
    mapUrl: "https://map.amiktechnologies.com",
    faviconUrl: "amikChargeFavicon.ico",
    filterColor:
      " brightness(0) saturate(100%) invert(33%) sepia(31%) saturate(4652%) hue-rotate(197deg) brightness(101%) contrast(110%)",
  },
  techvoltnetwork: {
    domain:"techvoltnetwork",
    palette: {
      primary: {
        lighter: "#52D28E",
        light: "#2FB873",
        main: "#149631",
        dark: "#0F6E24",
        darker: "#0A4B18",
        contrastText: "#fff"
      },      
    },
    logoUrl: "logo/techvoltNetwork.jpg",
    title: "Techvolt-CMS",
    mapUrl: "https://map.techvoltnetwork.com",
    faviconUrl: "techvoltNetworkFavicon.ico",
    filterColor:
      "invert(20%) sepia(100%) saturate(5000%) hue-rotate(100deg) brightness(95%) contrast(105%);",
  },
};
