import React, { useCallback, useMemo } from "react";
import { ActionsMenu } from "src/components/Actions/ActionsMenu";
import viewFill from "@iconify/icons-eva/eye-fill";
import editFill from "@iconify/icons-eva/edit-fill";

const ServiceRequestActionMenu = ({ row: { ticket_id } }) => {
  const viewRequest = useCallback(() => console.log("view"), []);

  const actionList = useMemo(
    () => [
      {
        id: "view",
        title: "View",
        icon: viewFill,
        // routerLink:`details/${ticket_id}`,
        action: viewRequest,
      },
      {
        id: "edit",
        title: "Edit",
        icon: editFill,
        routerLink: `edit/${ticket_id}`,
      },
    ],
    [viewRequest, ticket_id]
  );

  return <ActionsMenu menuList={actionList} />;
};

export default ServiceRequestActionMenu;
