import {
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import Page from "../Page";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  useGetDetail,
  useGetMeterValues,
  useMutate,
} from "src/hooks/useFetch.hook";
import { fDateTime, onlyTime } from "src/utils/formatTime";
import { BackButton } from "src/common/components/BackButton";
import { useAccess } from "src/hooks/useAccess.hook";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import enums, { FEATURE } from "src/util/enums";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";
import { Loader } from "../Loader";
import {
  chartLabelConstants,
  chartTypeToShow,
} from "./TransactionsList/TransactionsList.constants";
import { BaseChart } from "../charts/BaseOptionChart";
import { is } from "src/util/is";
import { Form, FormikProvider, useFormik } from "formik";
import { transactionStatusValidationSchema } from "src/util/validationSchemas";
import TransactionStatus from "../../data/transactionStatus";
import { getTeamUrl, transactionIdUrl } from "src/react-query/endPoints";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import downIcon from "@iconify/icons-eva/arrow-down-fill";
import upIcon from "@iconify/icons-eva/arrow-up-fill";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "start",
  color: theme.palette.text.secondary,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  width: "250px",
  height: "40px",
  fontSize: theme.spacing(2),
  fontWeight: 600
}))

export const GridLabel = ({ label, value, color, xs, sm, md, children }) => (
  <Grid item xs={xs} sm={sm} md={md}>
    <Item>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <h4>{label}</h4>
        {children ? (
          children
        ) : (
          <p style={{ color: color ?? "inherit" }}>{value ?? ""} </p>
        )}
      </div>
    </Item>
  </Grid>
);

const graphDataAccumulator = (dataArr) => {
  let accuObj = dataArr.reduce((accuObj, current) => {
    let val = is.empty(current.value) ? 0 : current.value;
    if (accuObj[current.measurand]) {
      accuObj[current.measurand] = {
        ...accuObj[current.measurand],
        xaxis: [
          ...accuObj[current.measurand]["xaxis"],
          new Date(current.value_timestamp).getTime(),
        ],
        yaxis: [...accuObj[current.measurand]["yaxis"], val],
      };
    } else {
      let initialTime = new Date(current.value_timestamp);
      initialTime.setMinutes(initialTime.getMinutes() - 5);

      accuObj[current.measurand] = {
        xaxis:
          current.measurand !== "Temperature"
            ? [new Date(current.value_timestamp).getTime()]
            : [
              initialTime.getTime(),
              new Date(current.value_timestamp).getTime(),
            ],
        yaxis: current.measurand !== "Temperature" ? [val] : [0, val],
        unit: current.unit,
        label: chartLabelConstants[current.measurand],
        measurand: current.measurand,
      };
    }
    return accuObj;
  }, {});
  const possibleMeasurands = Object.keys(accuObj);
  accuObj = { ...accuObj, possibleMeasurands };
  // console.log(accuObj);
  return accuObj;
};

export const TransactionView = () => {
  const { id } = useParams();
  const isRefundDetail = useMatch("/refund/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamId } = useSelectedTeam();
  const hasViewAccess = useAccess(FEATURE.TRANSACTION, "V");
  const hasReadOnlyAccess = useAccess(FEATURE.TRANSACTION, "R");
  const hasAllAccess = useAccess(FEATURE.TRANSACTION, "FA");
  const { mutate, isLoading } = useMutate("transactionStatus", { id });

  const [initialAmountLabel, setInitialAmountLabel] = React.useState("");
  const [remainingAmountLabel, setRemainingAmountLabel] = React.useState("");
  const [showMeterValues, setShowMeterValues] = React.useState(false);
  const [chartData, setChartData] = React.useState([]);

  const onSubmit = (transactionStatusObj, { setSubmitting }) => {
    const {
      actions: { setAlert },
    } = alertSlice;
    const { status } = transactionStatusObj;
    if (status === "None") {
      dispatch(
        setAlert({
          open: true,
          message: "Please select a status first",
          severity: enums.SEVERITY.ERROR,
        })
      );
      setSubmitting(false);
      return;
    }
    const mutateObject = {
      url: getTeamUrl(teamId, `${transactionIdUrl(id)}/status`),
      method: "PATCH",
    };

    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: transactionStatusObj,
      },
      {
        onSuccess: () => {
          dispatch(
            setAlert({
              open: true,
              message: "Succesfully updated the transaction status",
              severity: enums.SEVERITY.SUCCESS,
            })
          );
          // navigate(sidebarConfig[FEATURE.TRANSACTION].path)
        },
      }
    );
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: { notes: "", status: "" },
    validationSchema: transactionStatusValidationSchema,
    onSubmit: onSubmit,
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = formik;

  React.useEffect(() => {
    if (!hasViewAccess) {
      dispatch(
        alertSlice.actions.setAlert({ open: true, message: "Not Authorized" })
      );
      navigate(sidebarConfig[FEATURE.TRANSACTION].path);
    }
  }, [hasViewAccess, navigate, dispatch]);

  const { data, isFetching } = useGetDetail(
    isRefundDetail ? "refund" : "transaction",
    {
      id,
    }
  );
  const [transaction] = data ?? [];
  const { data: meterValues, isFetching: fetchingMeterValues } =
    useGetMeterValues("transactionMeterValue", {
      showMeterValues,
      transactionId: transaction ? transaction?.transactionId : null,
      connectorId: transaction ? transaction?.connectorId : null,
    });

  React.useEffect(() => {
    if (!is.empty(transaction) && !isFetching) {
      const { status, notes, payment_type } = transaction;
      let initialAmtLabel = "";
      let remainingAmtLabel = "";
      switch (payment_type) {
        case "WALLET":
          initialAmtLabel = "Initial Wallet Balance";
          remainingAmtLabel = "Remaining Wallet Balance";
          break;
        case "CARD":
          initialAmtLabel = "Total Paid Amount";
          remainingAmtLabel = "Refund Amount";
          break;
        case "PREPAID":
          initialAmtLabel = "Initial Card Balance";
          remainingAmtLabel = "Remaining Card Balance";
          break;
        case "RFID":
        default:
          break;
      }

      const formValues = {
        status: status ? status : "None",
        notes: notes ? notes : "",
      };
      console.log(formValues);
      resetForm({ values: formValues });
      setRemainingAmountLabel(remainingAmtLabel);
      setInitialAmountLabel(initialAmtLabel);
    }
  }, [transaction, resetForm, isFetching]);

  React.useEffect(() => {
    if (showMeterValues && meterValues) {
      const chartValues = graphDataAccumulator(meterValues);
      const measurandsArr = chartValues.possibleMeasurands;
      const finalData = measurandsArr.map(
        (measurand) => chartValues[measurand]
      );
      setChartData(finalData);
    }
  }, [showMeterValues, meterValues]);

  if (isFetching) {
    return <Loader />;
  }

  const viewLogs = () => {
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      data: { dataForLogs: transaction },
      tabOpenedFor: "charger",
      tabOpened: "logs"
    }))
    navigate(`/charger/${transaction?.charge_box_pk}`)
  };

  return (
    <Page
      title={`${!isRefundDetail ? "Transactions" : "Refund"
        }`}
    >
      <BackButton
        text={`${!isRefundDetail ? "Transaction" : "Refund"} details`}
        path={`${!isRefundDetail ? "/transactions" : "/refund"}`}
      />
      <Card sx={{ p: 2 }}>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          >
            <GridLabel
              label="Transaction ID"
              value={transaction?.transactionId}
              xs={12}
              sm={6}
              md={4}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="ChargerBox ID"
              value={transaction?.chargepoint_id}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label={"Chargepoint Name"}
              value={transaction?.chargepoint_name}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Connector ID"
              value={transaction?.connectorId}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Email"
              value={transaction?.email}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Phone Number"
              value={transaction?.phone ? transaction?.phone : "-"}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="OCPP ID Tag"
              value={transaction?.tag}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Start Time"
              value={fDateTime(transaction?.starttime, "yyyy-MM-dd hh:mm a")}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Stop Time"
              value={fDateTime(transaction?.stoptime, "yyyy-MM-dd hh:mm a")}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Start Meter Value"
              value={transaction?.start_meter_value}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Stop Meter Value"
              value={transaction?.stop_meter_value}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label="Charging Cost"
              value={transaction?.totalAmountCharged}
            />
            {initialAmountLabel && (
              <GridLabel
                xs={12}
                sm={6}
                md={4}
                label={initialAmountLabel}
                value={transaction?.totalAmountPaid}
              />
            )}
            {remainingAmountLabel && (
              <GridLabel
                xs={12}
                sm={6}
                md={4}
                label={remainingAmountLabel}
                value={transaction?.totalRefund}
              />
            )}
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label={"Payment Type"}
              value={transaction?.payment_type}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label={"Stop Reason"}
              value={transaction?.stop_meter_value ? transaction?.stop_reason ? transaction?.stop_reason : "Remote" : "--"}
            />
            <GridLabel
              xs={12}
              sm={6}
              md={4}
              label={"Total Kw Consumed"}
              value={transaction?.stop_meter_value ? Math.abs(transaction?.stop_meter_value - transaction?.start_meter_value) / 1000 : "--"}
            />
            {isRefundDetail && (
              <GridLabel
                xs={12}
                sm={6}
                md={4}
                label="Refund Status"
                value={transaction?.refundStatus.toUpperCase()}
              />
            )}
          </Grid>

        </Box>

        <Box mt={2} p={2} sx={{ display: "flex", placeContent: "end", gap: "1rem" }}>
          <Button variant="contained" onClick={viewLogs}>View Logs</Button>
          <Button
            variant="contained"
            color={showMeterValues ? "error" : "primary"}
            onClick={() => setShowMeterValues(!showMeterValues)}
            endIcon={<Icon icon={showMeterValues ? upIcon : downIcon}></Icon>}
          >
            {showMeterValues
              ? "Hide Charger Statistics"
              : "Show Charger Statistics"}
          </Button>
        </Box>
        {showMeterValues && chartData && chartData.length > 0 && (
          <Stack>
            <Grid container spacing={2}>
              {chartData.map(({ label, xaxis, yaxis, unit, measurand }) => {
                if (chartTypeToShow.includes(measurand)) {
                  let series = [
                    {
                      name: measurand,
                      data: yaxis,
                    },
                  ];
                  let options = {
                    chart: {
                      height: 200,
                      width: 300,
                      type: "line",
                      zoom: {
                        enabled: false,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "smooth",
                      width: 1,
                      lineCap: "butt",
                    },
                    title: {
                      text: label,
                      align: "left",
                    },
                    grid: {
                      row: {
                        colors: ["#C8FACD"],
                        opacity: 0.5,
                      },
                    },
                    xaxis: {
                      categories: xaxis,
                      type: "datetime",
                      timezone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      labels: {
                        formatter: (value) => onlyTime(value),
                        rotate: 0,
                        maxWidth: 10,
                      },
                      title: {
                        text: "Time",
                        style: {
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "grey",
                          marginTop: "2px",
                        },
                      },
                    },
                    yaxis: {
                      title: {
                        text: `${unit}`,
                        style: {
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "grey",
                        },
                      },
                    },
                  };

                  return (
                    <Grid item xs={12} md={6} sm={6} id={`chart-${label}`}>
                      <BaseChart
                        options={options}
                        series={series}
                        type="line"
                      />
                    </Grid>
                  );
                }
                return <></>;
              })}
            </Grid>
          </Stack>
        )}

        <Divider textAlign="left" variant="fullWidth" sx={{ mb: 4, mt: 6 }}>
          <StyledChip label="Transaction status and notes" />
        </Divider>
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            id={`transaction-status-form-${id}`}
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 2, sm: 3, md: 4 }}
              direction="row"
            >
              <Grid item xs={12} sm={12} md={12} >
                <FormControl

                  size="small"
                  error={touched.status && errors.status}
                >
                  <InputLabel id={`transaction-status-${id}`} >
                    Status
                  </InputLabel>
                  <Select
                    labelId={`transaction-status-${id}`}
                    id={`transaction-status-${id}`}
                    disabled={
                      hasReadOnlyAccess ||
                      values.status === "CAPTURED" ||
                      values.status === "AUTHORIZED"

                    }
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.target.value)}
                    label=" Status"
                    {...getFieldProps("status")}
                  >
                    <MenuItem selected value="None" disabled>
                      <em>None</em>
                    </MenuItem>
                    {TransactionStatus.map(
                      ({ label, value, disabled }, index) => {
                        return <MenuItem
                          key={`transaction-status-${index}-${label}`}
                          value={value}
                          disabled={disabled}
                        >
                          {label}
                        </MenuItem>
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} padding={1}>
                <FormControl
                  fullWidth
                >
                  <TextField
                    disabled={hasReadOnlyAccess}
                    multiline
                    sx={{ width: "60%" }}
                    rows={4}
                    id="transaction-notes"
                    label="Notes...."
                    {...getFieldProps("notes")}
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                  />
                </FormControl>
              </Grid>

              {hasAllAccess &&
                <Grid item xs={12} sm={12} md={12} padding={1}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItem="center"
                    justifyContent="left"
                  >
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting || isLoading}
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Grid>
              }
            </Grid>

          </Form>
        </FormikProvider>
      </Card>
    </Page>
  );
};
