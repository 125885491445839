import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import ImgPreviewModal from "src/components/ImagePreviewModal/ImgPreviewModal";
import { serviceRequestFormValidationSchema } from "src/util/validationSchemas";
import { fileToBase64 } from "src/utils/fileConvertBase64";
import {
  issueImgUpload,
  issueSubmission,
  useServiceRequestDetails,
} from "../serviceRequest.hook";
import { ServiceRequestFormSelectItems } from "./ServiceRequestForm.constants";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import enums, { FEATURE } from "src/util/enums";
import { Loader } from "src/components/Loader";
import { is } from "src/util/is";
import { alertSlice } from "src/store/alert/alert.slice";
import { useAuth } from "src/hooks/useAuth.hook";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { GridCloseIcon } from "@mui/x-data-grid";

const ServiceRequestForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { ticketId } = useParams();
  const isCreationOperation = useMatch("servicerequest/new");
  const {
    actions: { setAlert },
  } = alertSlice;
  const { data: serviceRequestDetails, isLoading } =
    useServiceRequestDetails(ticketId);
    console.log(user);
  const initialFormState = {
    email: user?.email,
    phone: "",
    issueType: "",
    description: "",
    attachment: { url: "" },
  };
  const [imageUploadPending, setImageUploadPending] = useState(false);
  const [uploadedImgUrl, setUploadedImageUrl] = useState("");
  const imageUploadInputRef = useRef();
  const imgUpload = useMutation(issueImgUpload);
  const submission = useMutation(issueSubmission);
  const [photoModal, setPhotoModal] = useState({open: false, src: ""});
  
  const handleImageUpload = (e) => {
    //  console.log(e.target.files[0])
    const file = e.target.files[0];
    const updatedImagePreview = { ...imagePreview, open: true, value: file };
    let updatedAttachmentObj = { ...values.attachment, file };
    formik.setFieldValue("attachment", updatedAttachmentObj);
    setImagePreview(updatedImagePreview);
  };

  const uploadImageHandler = async (file) => {
    const fileInBase64 = await fileToBase64(file);
    imgUpload.mutate(
      {
        image: fileInBase64,
      },
      {
        onSuccess: (response) => {
          // console.log(response)
          setImageUploadPending(false);
          const updatedAttachmentObj = {
            ...values.attachment,
            url: response.data.data,
          };
          formik.setFieldValue("attachment", updatedAttachmentObj);
          setUploadedImageUrl(response.data.data);
        },
      }
    );
    closePreviewModal();
    setImageUploadPending(true);
  };

  const cancelImageUpload = () => {
    formik.setFieldValue("attachment", {});
    closePreviewModal();
  };

  const closePreviewModal = () =>
    setImagePreview({ ...imagePreview, open: false });

  const resetFormBtnHandler = () => {
    formik.setFieldValue("attachment", {});
    setImagePreview({ ...imagePreview, value: "" });
  };

  const submitForm = (formData) => {
    let formPayload = { ...formData, attachment: uploadedImgUrl };
    if (!is.nullOrUndefined(ticketId) && !isCreationOperation) {
      formPayload = { ...formPayload, ticketId };
    }
    submission.mutate(formPayload, {
      onSuccess: (response) => {
        // console.log(response)
        if (
          response.data.status === "success" ||
          response.data.message === "Customer update successful!"
        ) {
          setImagePreview({ ...imagePreview, value: "" });
          navigate(sidebarConfig[FEATURE.SERVICEREQUEST].path);
          dispatch(
            setAlert({
              open: true,
              message: response.data.message,
              severity: enums.SEVERITY.SUCCESS, 
            })
          );
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: initialFormState,
    validationSchema: serviceRequestFormValidationSchema,
    onSubmit: submitForm,
    onReset: resetFormBtnHandler,
  });

  const {
    getFieldProps,
    handleChange,
    handleSubmit,
    handleReset,
    touched,
    errors,
    resetForm,
    values,
  } = formik;
  //  console.log(errors)
  const [imagePreview, setImagePreview] = useState({
    open: false,
    value: "",
    imagePreviewBtns: [
      {
        btnLabel: "Upload",
        action: uploadImageHandler,
        params: true,
      },
      {
        btnLabel: "Cancel",
        action: cancelImageUpload,
      },
    ],
  });

  useEffect(() => {
    console.log(imagePreview)
  }, [imagePreview])

  useEffect(() => {
    if (serviceRequestDetails) {
      const { attachment, description, email, issueType, phone } =
        serviceRequestDetails;
      const attachmentObj = { url: /^http/.test(attachment) ? attachment : "" };
      const formData = {
        attachment: attachmentObj,
        description,
        email,
        issueType,
        phone,
      };
      resetForm({ values: formData });
    }
  }, [serviceRequestDetails, resetForm]);

  if (isLoading && ticketId) {
    return <Loader />;
  }

  return (
    <>
      <Paper elevation={2}>
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{ height: "auto", width: "100%", p: 2 }}
            >
              <Grid
                item
                sx={{
                  py: 2,
                  display: "grid",
                  gap: "25px 15px",
                  gridTemplateColumns: "1fr 1fr",
                }}
                xs={12}
                lg={6}
              >
                <TextField
                  required
                  id="email-field"
                  label="Email"
                  disabled
                  {...getFieldProps("email")}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  required
                  id="mobile-no-field"
                  label="Mobile Number"
                  type="number"
                  {...getFieldProps("phone")}
                  onChange={handleChange}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <InputLabel id="issue-type-label">
                    Select the issue type
                  </InputLabel>
                  <Box width="196px">
                    <TextField
                      required
                      select
                      id="issue-type-select"
                      labelid="issue-type-label"
                      label="Issue"
                      fullWidth
                      {...getFieldProps("issueType")}
                      onChange={handleChange}
                      error={Boolean(touched.issueType && errors.issueType)}
                      helperText={touched.issueType && errors.issueType}
                    >
                      {ServiceRequestFormSelectItems.length > 0 &&
                        ServiceRequestFormSelectItems.map((item, index) => (
                          <MenuItem
                            value={item.value}
                            name={item.label}
                            key={`${index}-${item.label}`}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Box>
                </Stack>
              </Grid>
              <Grid item sx={{ py: 2 }} xs={12} lg={6}>
                <Typography variant="h6"> Issue</Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  mt={2}
                >
                  <TextField
                    required
                    multiline
                    sx={{ width: "400px" }}
                    rows={6}
                    id="issue-description"
                    label="Description"
                    {...getFieldProps("description")}
                    onChange={handleChange}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      {imageUploadPending ? (
                        <Box
                          sx={{
                            height: "170px",
                            width: "250px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : values.attachment &&
                        is.empty(values.attachment.url) ? (
                        <TextField
                          value={!imageUploadPending ? "" : "LOADING...."}
                          placeholder={"No file chosen"}
                          onChange={handleChange}
                          helperText={`Upload an image related to query.
                   (Max. 10MB - .JPEG,.JPG,.PNG)`}
                          disabled
                        />
                      ) : (
                        <ImageList cols={1}>
                          <ImageListItem key={`${values.attachment.url}-key`}>
                            <img
                              src={values.attachment.url}
                              alt={"attachment"}
                              style={{
                                maxHeight: "170px",
                                maxWidth: "250px",
                              }}
                              loading="eager"
                              onClick={() => {setPhotoModal({open: true, src: values.attachment.url})}}
                            />
                          </ImageListItem>
                        </ImageList>
                      )}
                      <LoadingButton
                        variant="contained"
                        onClick={() => imageUploadInputRef.current.click()}
                        loading={imageUploadPending}
                        loadingIndicator="Loading…"
                        endIcon={<Icon icon="uil:image-upload"></Icon>}
                      >
                        Upload
                        <input
                          accept="image/jpeg,image/png,image/jpg"
                          type="file"
                          hidden
                          id="issue-image"
                          ref={imageUploadInputRef}
                          onChange={handleImageUpload}
                        />
                      </LoadingButton>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      m={2}
                    >
                      <Button
                        variant="contained"
                        disabled={imageUploadPending}
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button variant="outlined" type="reset">
                        Reset
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Paper>
      {imagePreview.open && (
        <ImgPreviewModal
          imageObj={imagePreview}
          buttonsArray={imagePreview.imagePreviewBtns}
        />
      )}

      {photoModal &&
       <Dialog
       onClose={() => {setPhotoModal({open: false, src: ""})}}
       aria-labelledby="customized-dialog-title"
       open={photoModal.open}
      >
        <IconButton
        aria-label="close"
        onClick={() => {setPhotoModal({open: false, src: ""})}}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
        >
        <GridCloseIcon />
        </IconButton>
        <DialogContent sx={{minHeight: "300px", minWidth: "600px", display: "flex", placeItems: "center", placeContent: "center", padding:"32px 40px"}}>
          <img src= {photoModal.src} alt="Review" />
        </DialogContent>
      </Dialog>
      }
    </>
  );
};

export default ServiceRequestForm;
