//react
import { useEffect, useState, useMemo, useRef } from "react";
// router
import {
  useMatch,
  useNavigate,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider, Field } from "formik";

//mui components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import { LoadingButton } from "@mui/lab";

//custom constants, hooks and components
import {
  getPriceGroupDefaultValue,
  initialState,
} from "./PriceGroup.constants";
import { priceGroupValidationSchema } from "src/util/validationSchemas";
import enums, { FEATURE } from "src/util/enums";
import { Loader } from "../Loader";
import PriceGroupCopyModal from "./PriceGroupCopyModal";
import { is } from "src/util/is";
import { calculateTotalHours } from "src/utils/utility";
import {
  getTeamUrl,
  priceGroupIdUrl,
  priceGroupUrl,
} from "src/react-query/endPoints";
import { v4 as uuidv4 } from "uuid";

//icons
import { Icon } from "@iconify/react";
import AlertCircleFill from "@iconify/icons-eva/alert-circle-fill";
//hooks
import { useAccess } from "src/hooks/useAccess.hook";
import { useGetDetail, useGetPaymentDetailsForPriceGroup, useMutate } from "src/hooks/useFetch.hook";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";

//redux actions
import { alertSlice } from "src/store/alert/alert.slice";
import { dialogSlice } from "src/store/dailog/dailog.slice";
import { giveTimeAndUTCTimeOffset } from "src/utils/formatTime";

export const PriceGroupForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedRef = useRef(null);
  const [priceGroupState, setPriceGroupState] = useState(initialState);
  const { teamId } = useSelectedTeam();
  const isCreatePriceGroup = useMatch("/pricegroup/new");
  const { priceGroupId } = useParams();
  const hasReadOnlyAccess = useAccess(FEATURE.PRICEGROUP, "R");
  const { data: dialogData } = useSelector(({ dialog }) => dialog);

  const isReadOnly = hasReadOnlyAccess && !isCreatePriceGroup;
  const { data, isFetching } = useGetDetail("priceGroup", {
    id: priceGroupId,
  });
  const { mutate, isLoading } = useMutate("priceGroups");

  const {
    data: paymentDetails,
    isFetching: isPaymentDetailsFetching,
  } = useGetPaymentDetailsForPriceGroup(isCreatePriceGroup,teamId);
  


  const onSubmit = async (priceGroupData, { setSubmitting }) => {
    const {
      actions: { setAlert },
    } = alertSlice;
    const { priceGroupType } = priceGroupData;
    const mutateObject = {
      url: getTeamUrl(teamId, priceGroupUrl),
      method: "POST",
    };
    if (!is.nullOrUndefined(priceGroupId) && !isCreatePriceGroup) {
      mutateObject.method = "PUT";
      mutateObject.url = getTeamUrl(teamId, priceGroupIdUrl(priceGroupId));
    }

    let totalHours = calculateTotalHours(priceGroupData.priceDetails, -1);
    totalHours = Number(totalHours.toFixed(2));

    if (
      totalHours < 168 &&
      totalHours != 167.86 &&
      priceGroupType === enums.PRICE_TYPE.VARIABLE
    ) {
      dispatch(
        setAlert({
          open: true,
          message: `Please cover all time slots upto 24 hours`,
          severity: enums.SEVERITY.ERROR,
        })
      );
      return;
    }
    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: priceGroupData,
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const onSuccess = () => navigate("/pricegroup");

  const defaultValues = useMemo(
    () => getPriceGroupDefaultValue(teamId),
    [teamId]
  );

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: priceGroupValidationSchema,
    onSubmit,
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  const handleEvent = (e) => {
    const { day } = e.currentTarget.dataset;
    const toggle = !priceGroupState[day];
    selectedRef.current = toggle ? enums.DAYS[day] : null;

    setPriceGroupState({
      ...initialState,
      [day]: toggle,
    });
  };

  const onCheckedChanges = (e) => {
    const { name, checked } = e.target;
    const { DAYS_IN_STRING } = enums;
    const { copy } = initialState;
    const { copy: _copy } = priceGroupState;
    let copyDays = _copy;
    let isAll = false;

    if (name === "isAllSelected") {
      copyDays = checked ? DAYS_IN_STRING.map(() => !0) : copy;
      isAll = checked;
    }

    if (name.includes("day_")) {
      const _day = name.split("_")[1];
      const idx = DAYS_IN_STRING.findIndex((v) => v === _day);
      copyDays[idx] = checked;
      isAll = false;
    }

    setPriceGroupState((state) => ({
      ...state,
      copy: copyDays,
      isAll,
    }));
  };

  const onAddTimeRange =
    (idx = null, action = "A") =>
    () => {
      const { priceDetails } = values;
      const {
        actions: { setAlert },
      } = alertSlice;
      const dayValues = Object.values(priceGroupState);
      const hasSelectedDays = dayValues.filter((d) => d);

      if (is.null(idx) && action === "A") {
        const totalHours = Math.ceil(
          calculateTotalHours(priceDetails, selectedRef.current)
        );

        if (!hasSelectedDays.length) {
          dispatch(
            setAlert({
              open: true,
              message: "Please select the day before adding new time range",
              severity: enums.SEVERITY.ERROR,
            })
          );
          return;
        }

        if (totalHours >= 24) {
          dispatch(
            setAlert({
              open: true,
              message: `Already defined 24 hours for the selected day. Please adjust timeslot to define multiple prices`,
              severity: enums.SEVERITY.ERROR,
            })
          );
          return;
        }

        const { current } = selectedRef;
        const priceDetailCopy = { ...defaultValues.priceDetails[0] };
        const lastPriceDetail = priceDetails.findLast((p) => p.day === current);
        priceDetailCopy.startTime = lastPriceDetail.endTime;
        priceDetailCopy.day = selectedRef.current;
        setFieldValue("priceDetails", priceDetails.concat([priceDetailCopy]));
      }

      if (!is.null(idx) && action === "R") {
        setFieldValue(
          "priceDetails",
          priceDetails.filter((p, i) => i !== idx)
        );
      }

      if (!is.null(idx) && action === "S") {
        let priceDetailCopy = priceDetails[idx];
        priceDetailCopy = {
          ...defaultValues.priceDetails[0],
          day: priceDetailCopy.day,
        };
        priceDetails.splice(idx, 1, priceDetailCopy);
        setFieldValue("priceDetails", [...priceDetails]);
      }
    };

  const onHandleCopy =
    (day = null) =>
    () => {
      const { priceDetails } = values;
      const {
        actions: { setDialog },
      } = dialogSlice;
      const { copy, isAll } = priceGroupState;
      const { DAYS } = enums;
      let daysToCopy = [];
      let currentCopyDay = [];

      if (day) {
        dispatch(
          setDialog({
            open: true,
            id: { day },
          })
        );
      }

      if (!day) {
        currentCopyDay = priceDetails.filter(
          (c) => DAYS[dialogData.day] === c.day
        );
        copy.forEach((d, i) => {
          d &&
            (daysToCopy = daysToCopy.concat(
              currentCopyDay.map((c) => ({ ...c, day: i }))
            ));
          !d &&
            i !== DAYS[dialogData.day] &&
            (daysToCopy = daysToCopy.concat(
              priceDetails.filter((c) => c.day === i)
            ));
        });
        daysToCopy = isAll ? daysToCopy : daysToCopy.concat(currentCopyDay);
        dispatch(dialogSlice.actions.setDialog(false));
        setFieldValue("priceDetails", daysToCopy);
      }
    };

  const onHandleBlur = ({ name }) => {
    const matches = [...name.matchAll(/\[(\d+)]\.(\w+)/g)];
    const { priceDetails } = values;
    const filteredDays = priceDetails.filter(
      (f) => f.day !== selectedRef.current
    );
    const ranges = priceRangeInGroup[selectedRef.current];
    let [, index, fieldName] = matches[0];
    let rangeIndex = ranges.findIndex((r) => r.index === Number(index));

    if (fieldName === "startTime") {
      while (rangeIndex--) {
        ranges[rangeIndex].endTime = ranges[rangeIndex + 1].startTime;
      }
    }

    if (fieldName === "endTime") {
      while (rangeIndex++) {
        if (rangeIndex > ranges.length - 1) break;
        ranges[rangeIndex].startTime = ranges[rangeIndex - 1].endTime;
      }
    }

    setFieldValue("priceDetails", filteredDays.concat(ranges));
  };

  const priceGroupRangeSnippet = (detail) => {
    const timezoneOffsetStr = giveTimeAndUTCTimeOffset(
      new Date(new Date().toISOString())
    );
    return (
      <Grid container spacing={2} mt={3}>
        {values.priceGroupType === enums.PRICE_TYPE.VARIABLE && (
          <>
            <Grid item xs={12} md={6}>
              <Stack direction="row" spacing={2}>
                <FormLabel id="price-type">Timing</FormLabel>
                <FormControl fullWidth size="small">
                  <Field name={`priceDetails[${detail.index}].startTime`}>
                    {({ field }) => (
                      <TextField
                        label="Start Time"
                        size="small"
                        type="time"
                        defaultValue="00:00"
                        {...field}
                        onBlur={() => onHandleBlur(field)}
                        InputLabelProps={{ shrink: !0 }}
                        inputProps={{ min: "00:00", max: "23:59" }}
                        error={Boolean(
                          touched.priceDetails &&
                            errors.priceDetails &&
                            errors?.priceDetails[detail.index]?.startTime
                        )}
                        helperText={
                          touched.priceDetails &&
                          errors.priceDetails &&
                          errors?.priceDetails[detail.index]?.startTime
                        }
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth size="small">
                  <Field name={`priceDetails[${detail.index}].endTime`}>
                    {({ field }) => (
                      <TextField
                        label="End Time"
                        size="small"
                        type="time"
                        defaultValue="00:00"
                        {...field}
                        onBlur={() => onHandleBlur(field)}
                        InputLabelProps={{ shrink: !0 }}
                        inputProps={{ min: "00:00", max: "23:59" }}
                        error={Boolean(
                          touched.priceDetails &&
                            errors.priceDetails &&
                            errors?.priceDetails[detail.index]?.endTime
                        )}
                        helperText={
                          touched.priceDetails &&
                          errors.priceDetails &&
                          errors?.priceDetails[detail.index]?.endTime
                        }
                      />
                    )}
                  </Field>
                </FormControl>
              </Stack>
              <Typography
                component="span"
                sx={{ textAlign: "center", fontSize: "12px" }}
              >
                <p style={{ marginTop: "12px" }}>
                  Note : Timings should be in accordance with UTC timezone.
                </p>
                {timezoneOffsetStr}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                {priceRangeInGroup[detail.day].length > 1 && (
                  <Button
                    variant="contained"
                    onClick={onAddTimeRange(detail.index, "R")}
                    startIcon={<Icon icon="mdi:bin" />}
                  >
                    Remove
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={onAddTimeRange(detail.index, "S")}
                  startIcon={<Icon icon="material-symbols:device-reset" />}
                >
                  Reset Schedule
                </Button>
              </Stack>
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={12}>
          <Typography variant="subtitle1" className="mb-2">
            Details
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small">
              <Field name={`priceDetails[${detail.index}].priceperKW`}>
                {({ field }) => (
                  <TextField
                    label={"Price Per KWh"}
                    size="small"
                    disabled={isReadOnly}
                    {...field}
                    error={Boolean(
                      touched.priceDetails &&
                        errors.priceDetails &&
                        errors?.priceDetails[detail.index]?.priceperKW
                    )}
                    helperText={
                      touched.priceDetails &&
                      errors.priceDetails &&
                      errors?.priceDetails[detail.index]?.priceperKW
                    }
                  />
                )}
              </Field>
            </FormControl>

             {/* <FormControl fullWidth size="small">
               <Field name={`priceDetails[${detail.index}].priceperMin`}>
                 {({ field, form: { touched, errors } }) => (
                   <TextField
                     label={"Price Per Minute"}
                     size="small"
                     disabled={isReadOnly}
                     {...field}
                     error={Boolean(
                       touched.priceDetails &&
                         errors.priceDetails &&
                         errors?.priceDetails[detail.index]?.pricePerMin
                     )}
                     helperText={
                       touched.priceDetails &&
                       errors.priceDetails &&
                       errors?.priceDetails[detail.index]?.pricePerMin
                     }
                   />
                 )}
               </Field>
             </FormControl> */}
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth size="small">
            <Field name={`priceDetails[${detail.index}].priceType`}>
              {({ field }) => (
                <RadioGroup
                  // {...field}
                  row
                  aria-labelledby="row-radio-buttons-price-type"
                  defaultValue="kwh"
                >
                  <FormControlLabel
                    value="kwh"
                    label="KWh"
                    control={<Radio />}
                  />
                  {/* <FormControlLabel
                    value={enums.PRICE_PER.MIN}
                    label="Minute"
                    control={<Radio />}
                  /> */}
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            size="small"
            error={Boolean(touched.currency && errors.currency)}
          >
            <InputLabel id="currency">Currency</InputLabel>
            <Field name={`priceDetails[${detail.index}].currency`}>
              {({ field, form: { touched, errors } }) => (
                <>
                  <Select
                    labelId="currency"
                    id="currency_id"
                    disabled={isReadOnly}
                    label="Currency"
                    defaultValue={detail.currency}
                    {...field}
                  >
                    <MenuItem value="INR">Indian Rupee (INR)</MenuItem>
                    <MenuItem value="CAD">Canadian Dollar (CAD)</MenuItem>
                  </Select>
                  {touched.currency && (
                    <FormHelperText>{errors.currency}</FormHelperText>
                  )}
                </>
              )}
            </Field>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <Field name={`priceDetails[${detail.index}].fixedFeeStarting`}>
              {({ field, form: { touched, errors } }) => (
                <TextField
                  label="Fixed Fee Starting"
                  size="small"
                  disabled={isReadOnly}
                  {...field}
                  defaultValue={detail.fixedFeeStarting}
                  error={Boolean(
                    touched.priceDetails &&
                      errors.priceDetails &&
                      errors?.priceDetails[detail.index]?.fixedFeeStarting
                  )}
                  helperText={
                    touched.priceDetails &&
                    errors.priceDetails &&
                    errors?.priceDetails[detail.index]?.fixedFeeStarting
                  }
                />
              )}
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <Field name={`priceDetails[${detail.index}].fixedFeeCharging`}>
              {({ field, form: { touched, errors } }) => (
                <TextField
                  label="Fixed Fee Charging"
                  size="small"
                  disabled={isReadOnly}
                  {...field}
                  defaultValue={detail.fixedFeeCharging}
                  error={Boolean(
                    touched.priceDetails &&
                      errors.priceDetails &&
                      errors?.priceDetails[detail.index]?.fixedFeeCharging
                  )}
                  helperText={
                    touched.priceDetails &&
                    errors.priceDetails &&
                    errors?.priceDetails[detail.index]?.fixedFeeCharging
                  }
                />
              )}
            </Field>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <Field name={`priceDetails[${detail.index}].taxPercent`}>
              {({ field, form: { touched, errors } }) => (
                <TextField
                  label="Tax"
                  size="small"
                  disabled={true}
                  {...field}
                  defaultValue={detail.taxPercent}
                  error={Boolean(
                    touched.priceDetails &&
                      errors.priceDetails &&
                      errors?.priceDetails[detail.index]?.taxPercent
                  )}
                  helperText={
                    touched.priceDetails &&
                    errors.priceDetails &&
                    errors?.priceDetails[detail.index]?.taxPercent
                  }
                />
              )}
            </Field>
          </FormControl>
        </Grid> */}
      </Grid>
    );
  };

  const priceRangeInGroup = useMemo(() => {
    return values.priceDetails.reduce((prev, curr, i) => {
      prev[curr.day] = prev[curr.day] || [];
      prev[curr.day].push({ ...curr, index: i });
      return prev;
    }, {});
  }, [values]);

  useEffect(() => {
    if (data) {
      resetForm({ values: data });
    }
  }, [data, resetForm]);

  useEffect(() => {
    const { priceType } = values;
    const field =
      priceType === enums.PRICE_PER.KWH ? "priceperMin" : "priceperKW";
    setFieldValue(field, "");
  }, [values?.priceType]);

  useEffect(() => {
    const { priceGroupType } = values;
    const range = is.empty(data)
      ? defaultValues.priceDetails
      : data.priceDetails;

    if (priceGroupType === enums.PRICE_TYPE.FIXED) {
      const _fixedRange = range.length > 1 ? defaultValues.priceDetails : range;
      const fixedPriceDetails = { ..._fixedRange[0] };
      fixedPriceDetails.day = 0;
      setFieldValue("priceDetails", [fixedPriceDetails]);
    }

    if (priceGroupType === enums.PRICE_TYPE.VARIABLE) {
      selectedRef.current = enums.DAYS["MONDAY"];
      setFieldValue(
        "priceDetails",
        range.length > 1 ? range : defaultValues.priceDetails
      );
      setPriceGroupState((state) => ({
        ...state,
        MONDAY: !0,
      }));
    }
  }, [values?.priceGroupType, data]);

  useEffect(() => {
    if (
      isCreatePriceGroup &&
      !isPaymentDetailsFetching &&
      !is.empty(paymentDetails)
    ) {
      const {paymentGatewayId,currency,paymentGateway,taxPercent,transactionPercent} = paymentDetails[0];
      const updatedValues = {...values,paymentGatewayId,currency,paymentGateway,taxPercent,transactionPercent};
      resetForm({ values: updatedValues });
      // setFieldValue("currency", currency);
      // setFieldValue("paymentGatewayId", paymentGatewayId);
      // setFieldValue("paymentGateway", paymentGateway);
      // setFieldValue("transactionPercent", transactionPercent);
      // setFieldValue("taxPercent", taxPercent);
    }
  }, [isPaymentDetailsFetching,resetForm]);

  if (isFetching) {
    return <Loader />;
  }

  if (!isCreatePriceGroup && is.empty(data)) {
    navigate("/pricegroup");
    return null;
  }

  return (
    <Card sx={{ p: 2 }}>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          id="pricegroup"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl fullWidth size="small">
                  <TextField
                    required
                    disabled={isReadOnly}
                    label="Name"
                    size="small"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormControl>
                <span className="icon">
                  <Tooltip title="Price Group Name" placement="right">
                    <Icon
                      icon={AlertCircleFill}
                      className="fs-5"
                      color="#626262"
                    />
                  </Tooltip>
                </span>
              </Stack>
            </Grid>
            <Grid item xs={11.5} md={7.6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Description"
                  multiline
                  disabled={isReadOnly}
                  rows={3}
                  size="small"
                  {...getFieldProps("description")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11.5} md={7.6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Currency"
                  disabled
                  size="small"
                  {...getFieldProps("currency")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11.5} md={7.6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Payment Gateway"
                  disabled
                  size="small"
                  {...getFieldProps("paymentGateway")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11.5} md={7.6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Tax Percentage"
                  disabled
                  size="small"
                  {...getFieldProps("taxPercent")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11.5} md={7.6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Transaction Percentage"
                  disabled
                  size="small"
                  {...getFieldProps("transactionPercent")}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            rowGap={2}
            className={`time-range ${values.priceGroupType}`}
            alignItems="center"
          >
            <Grid item xs={12}>
              <Stack direction="row" flex={1} spacing={2} alignItems="center">
                <FormLabel id="price-type">Price Type</FormLabel>
                <FormControl fullWidth size="small">
                  <RadioGroup
                    row
                    {...getFieldProps("priceGroupType")}
                    aria-labelledby="row-radio-buttons-price-type"
                  >
                    <FormControlLabel
                      value={enums.PRICE_TYPE.FIXED}
                      label="Fixed"
                      control={<Radio />}
                    />
                    {/* <FormControlLabel
                      value={enums.PRICE_TYPE.VARIABLE}
                      label="Variable"
                      control={<Radio />}
                    /> */}
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
            {values.priceGroupType === enums.PRICE_TYPE.VARIABLE && (
              <>
                <Grid item xs={12} md={10}>
                  <Stack direction="row" spacing={1}>
                    {Object.keys(enums.DAYS).map((day) => (
                      <Chip
                        key={uuidv4()}
                        data-day={day}
                        className={`${priceGroupState[day] && "checked"}`}
                        onClick={handleEvent}
                        label={day}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item md={12} className="text-right">
                  <Button
                    variant="contained"
                    onClick={onHandleCopy(
                      enums.DAYS_IN_STRING[selectedRef.current]
                    )}
                    startIcon={<Icon icon="ic:baseline-content-copy" />}
                  >
                    Copy
                  </Button>
                  <Button
                    className="ml-3"
                    variant="contained"
                    onClick={onAddTimeRange()}
                    startIcon={<Icon icon="ic:baseline-plus" />}
                  >
                    Add
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container>
            {values.priceGroupType === enums.PRICE_TYPE.FIXED && (
              <Grid item xs={12}>
                {priceRangeInGroup[0] &&
                  priceRangeInGroup[0].map((detail) =>
                    priceGroupRangeSnippet(detail)
                  )}
              </Grid>
            )}
            {values.priceGroupType === enums.PRICE_TYPE.VARIABLE && (
              <Grid item xs={12}>
                {priceRangeInGroup[1] &&
                  priceGroupState[enums.DAYS_IN_STRING[1]] && (
                    <fieldset className="day-fieldset">
                      <legend>
                        {" "}
                        <Typography variant="h6">
                          {enums.DAYS_IN_STRING[1]}
                        </Typography>{" "}
                      </legend>
                      {priceRangeInGroup[1].map((detail) =>
                        priceGroupRangeSnippet(detail)
                      )}
                    </fieldset>
                  )}

                {priceRangeInGroup[2] &&
                  priceGroupState[enums.DAYS_IN_STRING[2]] && (
                    <fieldset className="day-fieldset">
                      <legend>
                        {" "}
                        <Typography variant="h6">
                          {enums.DAYS_IN_STRING[2]}
                        </Typography>{" "}
                      </legend>
                      {priceRangeInGroup[2].map((detail) =>
                        priceGroupRangeSnippet(detail)
                      )}
                    </fieldset>
                  )}

                {priceRangeInGroup[3] &&
                  priceGroupState[enums.DAYS_IN_STRING[3]] && (
                    <fieldset className="day-fieldset">
                      <legend>
                        {" "}
                        <Typography variant="h6">
                          {enums.DAYS_IN_STRING[3]}
                        </Typography>{" "}
                      </legend>
                      {priceRangeInGroup[3].map((detail) =>
                        priceGroupRangeSnippet(detail)
                      )}
                    </fieldset>
                  )}

                {priceRangeInGroup[4] &&
                  priceGroupState[enums.DAYS_IN_STRING[4]] && (
                    <fieldset className="day-fieldset">
                      <legend>
                        {" "}
                        <Typography variant="h6">
                          {enums.DAYS_IN_STRING[4]}
                        </Typography>{" "}
                      </legend>
                      {priceRangeInGroup[4].map((detail) =>
                        priceGroupRangeSnippet(detail)
                      )}
                    </fieldset>
                  )}

                {priceRangeInGroup[5] &&
                  priceGroupState[enums.DAYS_IN_STRING[5]] && (
                    <fieldset className="day-fieldset">
                      <legend>
                        {" "}
                        <Typography variant="h6">
                          {enums.DAYS_IN_STRING[5]}
                        </Typography>{" "}
                      </legend>
                      {priceRangeInGroup[5].map((detail) =>
                        priceGroupRangeSnippet(detail)
                      )}
                    </fieldset>
                  )}

                {priceRangeInGroup[6] &&
                  priceGroupState[enums.DAYS_IN_STRING[6]] && (
                    <fieldset className="day-fieldset">
                      <legend>
                        {" "}
                        <Typography variant="h6">
                          {enums.DAYS_IN_STRING[6]}
                        </Typography>{" "}
                      </legend>
                      {priceRangeInGroup[6].map((detail) =>
                        priceGroupRangeSnippet(detail)
                      )}
                    </fieldset>
                  )}

                {priceRangeInGroup[0] &&
                  priceGroupState[enums.DAYS_IN_STRING[0]] && (
                    <fieldset className="day-fieldset">
                      <legend>
                        {" "}
                        <Typography variant="h6">
                          {enums.DAYS_IN_STRING[0]}
                        </Typography>{" "}
                      </legend>
                      {priceRangeInGroup[0].map((detail) =>
                        priceGroupRangeSnippet(detail)
                      )}
                    </fieldset>
                  )}
              </Grid>
            )}
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={6} lg={6}>
              <Stack direction="row" spacing={2}>
                {!isReadOnly && (
                  <>
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={isReadOnly}
                      loading={isSubmitting || isLoading}
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      component={RouterLink}
                      to="/pricegroup"
                    >
                      Cancel
                    </LoadingButton>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <PriceGroupCopyModal
        isAllSelected={priceGroupState.isAll}
        checkedChange={onCheckedChanges}
        copiedDays={priceGroupState.copy}
        onClose={() => dispatch(dialogSlice.actions.setDialog(false))}
        onCopy={onHandleCopy()}
      />
    </Card>
  );
};
