import { useEffect, useState } from "react";
import { useGetPayoutList } from "src/hooks/useFetch.hook";
import { is } from "src/util/is";
import { BaseTable } from "../BaseTable/BaseTable";
import { teamPayoutsColumns } from "./teams.constants";
import { useNavigate } from "react-router-dom";
import { splitDateAndTime } from "../../utils/formatTime";

export default function TeamPayout({ id, formik }) {
  const [payoutListData, setPayoutList] = useState([]);
  const navigate = useNavigate();
  const { data: payoutList, isFetching } = useGetPayoutList(id);
  useEffect(() => {
    if (!is.empty(payoutList) && !is.empty(payoutList?.data)) {
      const formattedPayoutList = payoutList?.data.map((payout) => {
        return {
          ...payout,
          totalAmountCharged: payout.totalAmountCharged.toFixed(2),
          unitsConsumed: payout.unitsConsumed.toFixed(2),
          amikComission: payout.amikComission.toFixed(2),
          CPOPayout: payout.CPOPayout.toFixed(2),
          perUnitCommission: payout.perUnitCommission.toFixed(2),
          amikFixedComission: payout.amikFixedComission.toFixed(2),
          paymentGatewayFee: payout.paymentGatewayFee.toFixed(2),
          totalGSTOnAmount: payout.totalGSTOnAmount.toFixed(2),
          CPO_GST: payout.CPO_GST.toFixed(2),
          periodStartDate: splitDateAndTime(payout.periodStartDate),
          displayEndDate: splitDateAndTime(payout.displayEndDate),
          company: formik.values,
        };
      });
      setPayoutList(formattedPayoutList || []);
    }
  }, [payoutList]);

  const rowOrCellClickRedirect = ({ id }) => {
    navigate(`/transactions/?payoutId=${id}`);
  };

  // return (
  //   <PDFViewer height="750px" width="1000px">
  //     {payoutListData && payoutListData[0]?.payoutId && (
  //       <PayoutPdf company={formik.values} row={payoutListData[0]} />
  //     )}
  //   </PDFViewer>
  // );

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBlock: "20px",
          }}
        >
          <h2 style={{ margin: "5px" }}> Payouts</h2>
        </div>
        <BaseTable
          rows={payoutListData ?? []}
          columns={teamPayoutsColumns(false, false)}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          action
          getRowId={(row) => row.payoutId}
          loading={isFetching}
          pageSize={15}
        />
      </div>
    </>
  );
}
